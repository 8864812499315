import {
  Accessor,
  Component,
  JSXElement,
  Show,
  createEffect,
  createSignal,
  onMount,
  useContext,
} from 'solid-js';
import {
  Button,
  DialogParams,
  FaIcon,
  LocalizationContext,
  MarkdownEditor,
  callDialog
} from '../components';

export const OkDialog: Component<DialogParams<boolean> & {
  header: JSXElement;
  message?: JSXElement;
  ok?: JSXElement;
}> = (props) => {
  const { t } = useContext(LocalizationContext)!;

  return (
    <props.dialog class="sheet ui small dialog_ok" onDefaultAction={props.resolve}>
      <h1>{props.header}</h1>
      <Show when={props.message}>
        <p>{props.message}</p>
      </Show>
      <div class="footer">
        <div class="buttons align_end">
          <Button autofocus onClick={() => props.resolve()}>{props.ok ?? t('ok')}</Button>
        </div>
      </div>
    </props.dialog>
  );
};

export const OkCopyDialog: Component<DialogParams<boolean> & {
  header: JSXElement;
  message: JSXElement;
  text: string;
  note: JSXElement;
  ok?: JSXElement;
}> = (props) => {
  const { t } = useContext(LocalizationContext)!;

  return (
    <props.dialog class="sheet ui small dialog_ok_copy" onDefaultAction={props.resolve}>
      <h1>{props.header}</h1>
      <Show when={props.message}>
        <div class="message">{props.message}</div>
      </Show>
      <input type="text" value={props.text} readOnly onClick={(e) => e.currentTarget.select()} />
      <Button onClick={() => navigator.clipboard.writeText(props.text)} title={t('copy')}><FaIcon regular copy weak /></Button>
      <Show when={props.note}>
        <div class="note">{props.note}</div>
      </Show>
      <div class="footer">
        <div class="buttons align_end">
          <Button autofocus onClick={() => props.resolve()}>{props.ok ?? t('ok')}</Button>
        </div>
      </div>
    </props.dialog>
  );
};

export const ConfirmDialog: Component<DialogParams<boolean> & {
  header: JSXElement;
  message?: JSXElement;
  focus?: 'ok' | 'cancel';
  ok?: JSXElement;
  cancel?: JSXElement;
  danger?: boolean;
}> = (props) => {
  const { t } = useContext(LocalizationContext)!;

  return (
    <props.dialog class="sheet ui small dialog_confirm" onDefaultAction={() => {
      if((props.focus ?? 'ok') == 'ok') {
        props.resolve(true);
      }
    }}>
      <h1>{props.header}</h1>
      <Show when={props.message}>
        <p>{props.message}</p>
      </Show>
      <div class="footer">
        <div class="buttons align_end">
          <Button autofocus={(props.focus ?? 'ok') == 'ok'} onClick={() => props.resolve(true)} danger={props.danger}>{props.ok ?? t('ok')}</Button>
          <Button autofocus={props.focus == 'cancel'} onClick={() => props.resolve(false)}>{props.cancel ?? t('cancel')}</Button>
        </div>
      </div>
    </props.dialog>
  );
};

export const PromptDialog: Component<DialogParams<string | undefined> & {
  value?: string;
  header: JSXElement;
  message?: JSXElement;
  label?: JSXElement;
  ok?: JSXElement;
  cancel?: JSXElement;
}> = (props) => {
  const { t } = useContext(LocalizationContext)!;

  let refTextBox: HTMLInputElement | undefined;
  onMount(() => {
    if(props.value != null) {
      refTextBox!.value = props.value;
    }
  });

  const resolve = () => {
    props.resolve(refTextBox!.value);
  };

  return (
    <props.dialog class="sheet ui small dialog_prompt" onDefaultAction={resolve}>
      <h1>{props.header}</h1>
      <Show when={props.message}>
        <div class="message">{props.message}</div>
      </Show>
      <label class="input">
        <div class="label">{props.label}</div>
        <div class="input"><input type="text" ref={refTextBox} /></div>
      </label>
      <div class="footer">
        <div class="buttons align_end">
          <Button type="submit" onClick={resolve}>{props.ok ?? t('ok')}</Button>
          <Button onClick={() => props.resolve(undefined)}>{props.cancel ?? t('cancel')}</Button>
        </div>
      </div>
    </props.dialog>
  );
};

export const PromptMarkdownDialog: Component<DialogParams<string | undefined> & {
  value?: string;
  header: JSXElement;
  message?: JSXElement;
  label?: JSXElement;
  ok?: JSXElement;
  cancel?: JSXElement;
}> = (props) => {
  const { t } = useContext(LocalizationContext)!;

  const [text, setText] = createSignal(props.value ?? '');

  const resolve = () => {
    props.resolve(text());
  };

  return (
    <props.dialog class="sheet ui big dialog_prompt_markdown">
      <h1>{props.header}</h1>
      <Show when={props.message}>
        <div class="message">{props.message}</div>
      </Show>
      <label class="input">
        <div class="label">{props.label}</div>
        <MarkdownEditor text={text} setText={setText} level={2} ugc={true} linkInNewTab={true} />
      </label>
      <div class="footer">
        <div class="buttons align_end">
          <Button type="submit" onClick={resolve}>{props.ok ?? t('ok')}</Button>
          <Button onClick={() => props.resolve(undefined)}>{props.cancel ?? t('cancel')}</Button>
        </div>
      </div>
    </props.dialog>
  );
};

export const ProgressDialog: Component<DialogParams<boolean> & {
  header?: JSXElement;
  message?: JSXElement;
  progress: Accessor<[number, number] | true>;
  cancel?: JSXElement;
}> = (props) => {
  const { t } = useContext(LocalizationContext)!;
  createEffect(() => {
    if(props.progress() === true) {
      props.resolve(true);
    }
  });

  return (
    <props.dialog class="sheet ui dialog_progress" onDefaultAction={() => props.resolve(false)}>
      <h1>{props.header}</h1>
      <p>{props.message}</p>
      <div class="footer">
        <div class="buttons align_end">
          <Button onClick={() => props.resolve(false)}>{props.cancel ?? t('cancel')}</Button>
        </div>
      </div>
    </props.dialog>
  );
};

export const ErrorDialog: Component<DialogParams<boolean> & {
  header?: JSXElement;
  messageFunc?: (error: string) => string;
  message?: JSXElement;
  error: string;
  showRetry?: boolean;
  retry?: JSXElement;
  ok?: JSXElement;
}> = (props) => {
  const { t } = useContext(LocalizationContext)!;

  return (
    <props.dialog class="sheet ui dialog_error" onDefaultAction={() => props.resolve(true)}>
      <h1>{props.header ?? t('error_dialog.header')}</h1>
      <p>{props.messageFunc ? props.messageFunc(props.error) : (props.message ?? t('error_dialog.messageFunc')(props.error))}</p>
      <div class="footer">
        <div class="buttons align_end">
          <Show when={props.showRetry}>
            <Button onClick={() => props.resolve(true)}>{props.retry ?? t('retry')}</Button>
          </Show>
          <Button onClick={() => props.resolve(false)}>{props.ok ?? t('ok')}</Button>
        </div>
      </div>
    </props.dialog>
  );
};

export const showErrorScope = async <T,>(scope: () => Promise<T>, props = {}): Promise<T | undefined> => {
  for(;;) {
    try {
      return await scope();
    } catch(error) {
      console.log(error);
      if(!await callDialog(ErrorDialog, {
        ...props,
        error: String(error),
      })) {
        break;
      }
    }
  }
};
