import {
  Set as ISet,
} from 'immutable';
import {
  Component,
  For,
  JSXElement,
  Show,
  createMemo,
  createSignal,
  useContext,
} from 'solid-js';
import {
  ArchiveChunksIndex,
  Button,
  ChunkingAlgorithm,
  CompressionAlgorithm,
  DialogParams,
  DirectoryEntry,
  EntryContextProvider,
  EntryUploadExtra,
  FaIcon,
  FileEntry,
  LocalizationContext,
  PrettyDataSize,
  Tags,
  TagsEditor,
  UploadBox,
  calculateArchiveChunksIndex,
  compareTags,
} from '../components';
import { ProjectInfo, ProjectQuotasInfo } from '../components/api-generated';

export type NewPackage = {
  title: string;
  tags: ISet<string>;
  root: DirectoryEntry;
  chunkingAlgorithm: ChunkingAlgorithm;
  compressionAlgorithm: CompressionAlgorithm;
  baseChunksIndex?: ArchiveChunksIndex;
};

export const NewPackageDialog: Component<DialogParams<NewPackage> & {
  project: ProjectInfo;
  projectQuotas: ProjectQuotasInfo;
  version?: string;
  baseRoot?: DirectoryEntry;
  cloneFilesFromBase?: boolean;
  tags?: ISet<string>;
}> = (props) => {
  const { t } = useContext(LocalizationContext)!;
  const [tags, setTags] = createSignal(props.tags ?? ISet<string>());

  const root = (props.baseRoot && props.cloneFilesFromBase) ? props.baseRoot.clone() : new DirectoryEntry('');

  let refTitle: HTMLInputElement | undefined;

  const tagsStr = createMemo<string>((lastTagsStr) => {
    const tagsStr = (props.version ? [props.version] : []).concat(tags().toArray().sort(compareTags).map((tag) => tag.split(':').at(-1)!)).join('-');
    if(refTitle?.value === '' || refTitle?.value === lastTagsStr) {
      refTitle!.value = tagsStr;
    }
    return tagsStr;
  }, '');

  const onTitleBlur = (e: FocusEvent) => {
    if(refTitle?.value === '') {
      refTitle!.value = tagsStr();
    }
  };

  type Check = {
    message: JSXElement;
    action?: {
      title: JSXElement;
      fix: () => void;
    };
  };
  const checkSingleRootDir = createMemo<Check | null>(() => {
    const entries = root.entries();
    if(entries.size == 1 && entries.first()! instanceof DirectoryEntry) {
      return {
        message: t('packages.new_dialog.checks.single_root_dir'),
        action: {
          title: t('packages.new_dialog.checks.single_root_dir.action'),
          fix: () => {
            if(entries.size == 1) {
              const entry = entries.first();
              if(entry instanceof DirectoryEntry) {
                entry.removeFromParent();
                for(const [_subentryName, subentry] of entry.entries()) {
                  subentry.moveTo(root);
                }
              }
            }
          },
        },
      };
    } else return null;
  });
  const checkIndexHtml = createMemo<Check | null>(() => {
    const entries = root.entries();
    if(tags().has('os:web')) return null;
    const subentry = entries.get('index.html');
    if(subentry instanceof FileEntry) {
      return {
        message: t('packages.new_dialog.checks.index_html'),
        action: {
          title: t('packages.new_dialog.checks.index_html.action'),
          fix: () => {
            setTags((tags) => tags.add('os:web'));
          },
        },
      };
    }
    return null;
  });
  const checks = createMemo(() => [
    checkSingleRootDir(),
    checkIndexHtml(),
  ].filter((check) => check != null));

  const chunkingAlgorithm = ChunkingAlgorithm.Gear17;
  const compressionAlgorithm = CompressionAlgorithm.None;
  const baseChunksIndex = props.baseRoot ? calculateArchiveChunksIndex(props.baseRoot) : undefined;

  const onStartUpload = () => {
    props.resolve({
      title: refTitle!.value,
      tags: tags(),
      root,
      chunkingAlgorithm,
      compressionAlgorithm,
      baseChunksIndex,
    });
  };

  return (
    <props.dialog class="sheet ui big new_package">
      <h1>{t('packages.new_dialog.header')}</h1>
      <h2>{t('packages.new_dialog.tags.header')}</h2>
      <TagsEditor tags={tags} setTags={setTags} />
      <Tags tags={tags()} />
      <h2>{t('packages.new_dialog.files.header')}</h2>
      <EntryContextProvider>
        <UploadBox
          root={root}
          baseRoot={props.baseRoot}
          baseChunksIndex={baseChunksIndex}
          editable
        />
      </EntryContextProvider>
      <Show when={checks().length > 0}>
        <div class="checks">
          <For each={checks()}>{(check) =>
            <div>
              <div class="message">{check?.message}</div>
              <Show when={check?.action}>
                <div class="buttons">
                  <Button onClick={check?.action?.fix}>{check?.action?.title}</Button>
                </div>
              </Show>
            </div>
          }</For>
        </div>
      </Show>
      <div class="size">{t('packages.new_dialog.total_size')(
        <span class="inline_size"><PrettyDataSize size={root.totalSize()} /></span>,
        <span class="note">{t('packages.new_dialog.total_size.maximum')(<span class="inline_size"><PrettyDataSize size={props.projectQuotas.packageSize} /></span>)}</span>
      )}</div>
      <div class="size">{t('packages.new_dialog.new_data_size')(
        <Show when={(root.extra as EntryUploadExtra).ready()} fallback={
          <span class="note">{t('calculating')}</span>
        }>
          <span class="inline_size"><PrettyDataSize size={(root.extra as EntryUploadExtra)?.patchSize?.(props.baseRoot)?.() ?? 0} /></span>
        </Show>
      )}</div>
      <label class="title">
        <div class="label">{t('packages.new_dialog.label_title')}</div>
        <div class="value"><input type="text" ref={refTitle} placeholder={tagsStr() || t('packages.new_dialog.title_placeholder')} onBlur={onTitleBlur} autofocus /></div>
      </label>
      <div class="footer">
        <div class="buttons align_end">
          <Button onClick={onStartUpload} disabled={root.entries().isEmpty() || !(root.extra as EntryUploadExtra)?.ready?.()}><FaIcon inline solid cloud-arrow-up />{t('upload_box.start_upload')}</Button>
          <Button onClick={() => props.resolve()}>{t('cancel')}</Button>
        </div>
      </div>
    </props.dialog>
  );
};
