const dict = {
  languageName: 'English',
  locale: 'en',
  fallback: '',
  dir: 'ltr',

  // app
  'app.name': 'Kavykhi.Cloud',
  'app.title': (sub) => `${sub} - ${dict['app.name']}`,

  // themes
  theme: 'Theme',
  'theme.light': 'Light',
  'theme.dark': 'Dark',
  'theme.system': 'System',

  // generic
  email: 'Email',
  password: 'Password',
  ok: 'OK',
  cancel: 'Cancel',
  retry: 'Retry',
  copy: 'Copy',
  empty: '(no items)',
  loading: 'Loading...',
  calculating: 'Calculating...',
  error: 'Error',

  'in_development': 'This page is in development.',

  // errors
  'error.not_found': 'not found',
  'error.name_conflict': 'name conflict',
  'error.bad_param': 'bad param',
  'error.wrong_auth': 'wrong auth',
  'error.unauthorized': 'unauthorized',
  'error.wrong_credentials': 'wrong credentials',
  'error.wrong_provider': 'wrong provider',
  'error.insufficient_privileges': 'insufficient privileges',
  'error.not_empty': 'not empty',
  'error.limit_exceeded': 'limit exceeded',
  'error.already_member': 'already member',
  'error.not_finalized': 'not finalized',
  'error.already_finalized': 'already finalized',
  'error.unexpected_patch': 'unexpected patch',
  'error.timeout': 'timeout',
  'error.not_patch': 'not patch',
  'error.not_supported': 'not supported',
  'error.unexpected_error': 'unexpected error',

  // data sizes
  'datasize.B': 'B',
  'datasize.KiB': 'KiB',
  'datasize.MiB': 'MiB',
  'datasize.GiB': 'GiB',
  'datasize.TiB': 'TiB',

  // auth providers
  'auth.provider.steam': 'Steam',
  'auth.provider.github': 'GitHub',
  'auth.provider.gitlab': 'GitLab',
  'auth.provider.itch': 'itch.io',
  'auth.provider.twitch': 'Twitch',
  'auth.provider.discord': 'Discord',

  // auth dialog
  'auth.dialog.header': 'Authentication',
  'auth.dialog.please_select': 'Please select your account provider to sign in.',
  'auth.dialog.redirecting_to': (provider) => `Redirecting to ${provider}...`,
  'auth.dialog.remember_me': 'Remember me',
  'auth.dialog.log_in': 'Log in',
  'auth.dialog.back': 'Back',
  'auth.dialog.progress_header': 'Authentication...',

  'user.profile': 'Profile',
  'user.logout': 'Log out',

  // deploy credential providers
  'deploy_credential_provider.steam': 'Steam',
  'deploy_credential_provider.itch': 'itch.io',

  'page_not_found.header': 'Page not found',
  'page_not_found.message': 'This page does not exist.',
  'page_not_found.message2': 'Well, it kind of does, but there\'s nothing here. Here are the possibilities.',
  'page_not_found.items': [
    'Something was here before, but it is deleted now.',
    'Something is still here, but you do not have access.',
    'Or somebody was simply not careful while copying and pasting the link.',
  ],

  'route.home': 'Home',
  'home.header.hint': 'Cloud tools for indie devs',
  'home.auth': 'Sign in or sign up',

  'route.projects': 'Projects',
  'route.projects.hint': 'The projects you are working on',
  'projects.project': (project) => `Project: ${project}`,
  'projects.project.hint': 'project',
  'projects.no_projects': 'You have no projects yet.',
  'projects.new': 'Create project',
  'projects.new_dialog.header': 'New project',
  'projects.new_dialog.label': 'Project name',
  'projects.new_dialog.ok': 'Create project',
  'projects.store_page': 'Public store page',
  'projects.description': 'Description',
  'projects.branches': 'Branches',
  'projects.versions': 'Versions',
  'projects.packages': 'Packages',
  'projects.management': 'Project settings',
  'projects.management.breadcrumb': 'Settings',
  'projects.management.title': (projectTitle) => `${projectTitle} - settings`,
  'projects.management.hint': 'project settings',
  'projects.management.users': 'Team members',
  'projects.management.users.empty': 'No team members.',
  'projects.management.users.role.tester': 'Tester',
  'projects.management.users.role.developer': 'Developer',
  'projects.management.users.role.admin': 'Administrator',
  'projects.management.users.role.owner': 'Owner',
  'projects.management.users.exile': 'Remove from team',
  'projects.management.users.exile.dialog.header': 'Remove from team',
  'projects.management.users.exile.dialog.message': (projectTitle, userTitle) => <>Are you sure you want to remove <strong>{userTitle}</strong> from <strong>{projectTitle}</strong> project?</>,
  'projects.management.users.exile.dialog.ok': 'Remove from team',
  'projects.management.invites.one_time_invite': 'One-time invite link',
  'projects.management.invites.one_time_invite.header': 'One-time invite link',
  'projects.management.invites.one_time_invite.message': 'One-time invite link:',
  'projects.management.invites.one_time_invite.note': 'Valid for 24 hours, can be used once.',
  'projects.management.invites.new_dialog.header': 'Invite link',
  'projects.management.invites.new_dialog.expires': 'Expires in:',
  'projects.management.invites.new_dialog.expires.indefinite': '(no expiration)',
  'projects.management.invites.new_dialog.approval.label': 'User joins project:',
  'projects.management.invites.new_dialog.approval.not_approved': 'after admin approval',
  'projects.management.invites.new_dialog.approval.approved': 'immediately',
  'projects.management.invites.new_dialog.seats': 'Limit joins:',
  'projects.management.invites.new_dialog.seats.no_limit': '(no limit)',
  'projects.management.invites.new_dialog.ok': 'Create invite link',
  'projects.management.deploy_sites': 'Deploy sites',
  'projects.management.deploy_sites.empty': 'No deploy sites added.',
  'projects.management.deploy_sites.add': 'Add deploy site',
  'projects.management.deploy_sites.delete': 'Delete',
  'projects.management.deploy_sites.delete_dialog.header': 'Delete deploy site',
  'projects.management.deploy_sites.delete_dialog.message': (deploySite) => <>Are you sure you want to delete <strong>{dict[`deploy_credential_provider.${deploySite.provider}`]}</strong> deploy site <strong>{deploySite.site}</strong>?</>,
  'projects.management.deploy_sites.delete_dialog.ok': 'Delete deploy site',
  'projects.management.public_access': 'Public access',
  'projects.management.public_access.enable': 'Enable public access',
  'projects.management.public_access.enabled': <>Public access: <strong>allowed</strong></>,
  'projects.management.public_access.enabled.note': 'The project\'s name and description are public, as well as any branches marked public.',
  'projects.management.public_access.enable_dialog.header': 'Enable public access',
  'projects.management.public_access.enable_dialog.message': (projectTitle) => <>Are you sure you want to allow public access to the project <strong>{projectTitle}</strong>?<br /><br />This will make basic information about the project available without authentication. You will need to separately enable public access for each branch you want to be public.</>,
  'projects.management.public_access.enable_dialog.ok': 'Enable public access',
  'projects.management.public_access.disable': 'Disable public access',
  'projects.management.public_access.disabled': <>Public access: <strong>not allowed</strong></>,
  'projects.management.public_access.disabled.note': 'The project is private. Only the members of the project team can access it.',
  'projects.management.public_access.disable_dialog.header': 'Disable public access',
  'projects.management.public_access.disable_dialog.message': (projectTitle) => <>Are you sure you want to disable all public access to the project <strong>{projectTitle}</strong>?</>,
  'projects.management.public_access.disable_dialog.ok': 'Disable public access',
  'projects.management.public_access.branches': 'Public branches',
  'projects.management.manage': 'Manage project',
  'projects.rename': 'Rename project',
  'projects.rename_dialog.header': 'Rename project',
  'projects.rename_dialog.label': 'Project name:',
  'projects.rename_dialog.ok': 'Rename project',
  'projects.edit_description': 'Edit project description',
  'projects.edit_description_dialog.header': 'Edit project description',
  'projects.edit_description_dialog.label': 'Project description:',
  'projects.edit_description_dialog.ok': 'Edit description',
  'projects.leave': 'Leave project',
  'projects.leave_dialog.header': 'Leave project',
  'projects.leave_dialog.message': (projectTitle) => <>Are you sure you want to leave <strong>{projectTitle}</strong> project?</>,
  'projects.leave_dialog.ok': 'Leave project',
  'projects.delete': 'Delete project',
  'projects.delete_dialog.header': 'Delete project',
  'projects.delete_dialog.message': (projectTitle) => <>Are you sure you want to delete <strong>{projectTitle}</strong> project?</>,
  'projects.delete_dialog.ok': 'Delete project',

  'branches.header': (projectTitle, branchTitle) => `${projectTitle}: ${branchTitle}`,
  'branches.header.hint': 'branch',
  'branches.no_branches': 'The project has no branches yet',
  'branches.new': 'Create branch',
  'branches.new_dialog.header': 'New branch',
  'branches.new_dialog.label': 'Branch name',
  'branches.new_dialog.ok': 'Create branch',
  'branches.current_version': 'Current version',
  'branches.no_current_version': 'No current version',
  'branches.publish_version': 'Publish version in this branch',
  'branches.publish_version.new': 'New version',
  'branches.publish_version.existing': 'Existing version',
  'branches.version_history': 'Version history',
  'branches.version_history.empty': 'Version history of the branch is empty.',
  'branches.version_history.note_removal': ({ quotaCount, quotaNonCurrentTime, quotaTime }) => <>Current branch version is kept {quotaTime != null ? <>for <strong>{quotaTime}</strong></> : <>indefinitely</>}. Previous versions are kept for at most <strong>{quotaNonCurrentTime}</strong>. Not more than {plural(quotaCount, <><strong>{quotaCount}</strong> version</>, <><strong>{quotaCount}</strong> versions</>)} total are kept per branch.</>,
  'branches.manage': 'Manage branch',
  'branches.rename': 'Rename branch',
  'branches.rename_dialog.header': 'Rename branch',
  'branches.rename_dialog.label': 'Branch name:',
  'branches.rename_dialog.ok': 'Rename branch',
  'branches.delete': 'Delete branch',
  'branches.delete_dialog.header': 'Delete branch',
  'branches.delete_dialog.message': (branchTitle) => <>Are you sure you want to delete <strong>{branchTitle}</strong> branch?</>,
  'branches.delete_dialog.ok': 'Delete branch',

  'versions.header': (projectTitle, versionTitle) => `${projectTitle} ${versionTitle}`,
  'versions.header.hint': 'version',
  'versions.current': 'Current',
  'versions.previous': 'Previous',
  'versions.deploy_to_site.header': 'Publish to site',
  'versions.deploy_to_site': 'Publish to site',
  'versions.packages': 'Packages',
  'versions.branches': 'In branches',
  'versions.branches.publish': 'Publish in branch',
  'versions.branches.empty': 'Version has not been published in any branches.',
  'versions.new_dialog.header': 'New version',
  'versions.new_dialog.label_title': 'Version:',
  'versions.new_dialog.label_branch': 'Branch:',
  'versions.new_dialog.packages.header': 'Packages',
  'versions.new_dialog.packages.empty': 'No packages added.',
  'versions.new_dialog.packages.add': 'Add package',
  'versions.new_dialog.packages.add_existing': 'Add previously uploaded package',
  'versions.new_dialog.packages.replace': 'Replace package',
  'versions.new_dialog.packages.edit': 'Edit package',
  'versions.new_dialog.ok': 'Create version',
  'versions.manage': 'Manage version',
  'versions.rename': 'Rename version',
  'versions.rename_dialog.header': 'Rename version',
  'versions.rename_dialog.label': 'Version name:',
  'versions.rename_dialog.ok': 'Rename version',
  'versions.delete': 'Delete version',
  'versions.delete_dialog.header': 'Delete version',
  'versions.delete_dialog.message': (versionTitle) => <>Are you sure you want to delete version <strong>{versionTitle}</strong>?</>,
  'versions.delete_dialog.ok': 'Delete version',

  'versions.publish_dialog.header': 'Publish version',
  'versions.publish_dialog.message': (versionTitle) => <>Choose branch to publish version <strong>{versionTitle}</strong> in.</>,
  'versions.publish_dialog.no_branches': 'No branches in the project.',
  'versions.publish_dialog.ok': 'Publish version',

  'versions.publish_existing_dialog.header': 'Publish version',
  'versions.publish_existing_dialog.message': 'Last versions:',
  'versions.publish_existing_dialog.no_versions': 'No versions in the project.',
  'versions.publish_existing_dialog.ok': 'Publish version',

  'packages.header': (projectTitle, packageTitle) => `${projectTitle}: ${packageTitle}`,
  'packages.header.hint': 'Package',
  'packages.download_zip': 'Download as ZIP',
  'packages.download_torrent': 'Download torrent',
  'packages.total_size': (size) => <>Total size: {size}</>,
  'packages.manage': 'Manage package',
  'packages.rename': 'Rename package',
  'packages.rename_dialog.header': 'Rename package',
  'packages.rename_dialog.label': 'Package name:',
  'packages.rename_dialog.ok': 'Rename package',
  'packages.edit_tags': 'Edit package tags',
  'packages.edit_tags_dialog.header': 'Edit package tags',
  'packages.edit_tags_dialog.message': (packageTitle) => <>Specify tags of package <strong>{packageTitle}</strong>:</>,
  'packages.edit_tags_dialog.ok': 'Set package tags',
  'packages.delete': 'Delete package',
  'packages.delete_dialog.header': 'Delete package',
  'packages.delete_dialog.message': (packageTitle) => <>Are you sure you want to delete package <strong>{packageTitle}</strong>? The package will be deleted from all versions it was added to.</>,
  'packages.delete_dialog.ok': 'Delete package',

  'packages.new_dialog.header': 'New package',
  'packages.new_dialog.label_title': 'Title:',
  'packages.new_dialog.title_placeholder': '(from tags)',
  'packages.new_dialog.tags.header': 'Tags',
  'packages.new_dialog.files.header': 'Files',
  'packages.new_dialog.total_size': (size, maximum) => <>Total size: {size} {maximum}</>,
  'packages.new_dialog.total_size.maximum': (size) => <>(maximum {size})</>,
  'packages.new_dialog.new_data_size': (size) => <>New data size: {size}</>,
  'packages.new_dialog.checks.single_root_dir': 'It is recommended to place files directly into root, rather than have a single directory at root.',
  'packages.new_dialog.checks.single_root_dir.action': 'Replace folder with its contents',
  'packages.new_dialog.checks.index_html': <>There's <code>index.html</code> at root. Add Web tag to make package playable in browser.</>,
  'packages.new_dialog.checks.index_html.action': 'Add Web tag',

  'packages.add_existing_dialog.header': 'Add package',
  'packages.add_existing_dialog.add': (packagesCount) => packagesCount > 0 ? `Add ${plural(packagesCount, `${packagesCount} package`, `${packagesCount} packages`)}` : 'No packages selected',
  'packages.add_existing_dialog.message': 'Last uploaded packages:',
  'packages.add_existing_dialog.no_packages': 'No recently uploaded packages.',

  'packages.upload.status.existing': 'From previous version',
  'packages.upload.status.queued': 'Queued',
  'packages.upload.status.packing': 'Packing...',
  'packages.upload.status.creating': 'Creating...',
  'packages.upload.status.create_retrying': 'Creating failed, waiting and retrying',
  'packages.upload.status.splitting': 'Splitting...',
  'packages.upload.status.uploading': 'Uploading...',
  'packages.upload.status.finalizing': 'Finalizing...',
  'packages.upload.status.finalize_retrying': 'Finalize failed, waiting and retrying',
  'packages.upload.status.resolving': 'Resolving patch...',
  'packages.upload.status.resolve_retrying': 'Resolve patch failed, waiting and retrying...',
  'packages.upload.status.done': 'Uploaded successfully',
  'packages.upload.status.errored': 'Error',

  'packages.upload.part.status.queued': 'Queued',
  'packages.upload.part.status.hashing': 'Hashing',
  'packages.upload.part.status.upload_queued': 'Upload queued',
  'packages.upload.part.status.uploading': 'Uploading',
  'packages.upload.part.status.upload_retrying': 'Error, retrying upload',
  'packages.upload.part.status.upload_finalizing': 'Finalizing upload',
  'packages.upload.part.status.upload_errored': 'Error',
  'packages.upload.part.status.done': 'Uploaded',

  'packages.play': 'Play in browser',
  'packages.play.reset': 'Reset',
  'packages.play.fullscreen': 'Fullscreen',

  'packages.cloud_play': 'Play in cloud',
  'packages.cloud_play.start': 'Start cloud play',
  'packages.cloud_play.fullscreen': 'Fullscreen',
  'packages.cloud_play.status.not_started': 'Not started',
  'packages.cloud_play.status.creating_session': 'Creating session...',
  'packages.cloud_play.status.awaiting_machine': 'Awaiting machine...',
  'packages.cloud_play.status.connecting': 'Connecting...',
  'packages.cloud_play.status.playing': 'Playing',
  'packages.cloud_play.status.error': 'Error',

  'deploy_sites.add_dialog.header': 'Add deploy site',
  'deploy_sites.add_dialog.message': (projectTitle) => <>Choose deploy credential and enter site URL to save them as a deploy site for the project <strong>{projectTitle}</strong>.</>,
  'deploy_sites.add_dialog.label_credential': 'Deploy credential:',
  'deploy_sites.add_dialog.select_credential': 'Select credential',
  'deploy_sites.add_dialog.select_credential.new': 'Add new credential...',
  'deploy_sites.add_dialog.label_select_site': 'Select site:',
  'deploy_sites.add_dialog.select_credential_first': '(select credential first)',
  'deploy_sites.add_dialog.select_site': '(select site)',
  'deploy_sites.add_dialog.label_enter_site': 'Or enter site URL:',
  'deploy_sites.add_dialog.ok': 'Add site',

  'deploy_to_site_dialog.header': 'Publish to site',
  'deploy_to_site_dialog.message': (projectTitle, versionTitle) => <>Choose site to publish the version <strong>{versionTitle}</strong> of <strong>{projectTitle}</strong>.</>,
  'deploy_to_site_dialog.label_site': 'Publish to site:',
  'deploy_to_site_dialog.select_site': 'Select site',
  'deploy_to_site_dialog.select_site.new': 'Add new deploy site...',
  'deploy_to_site_dialog.packages.message.itch': <>Packages will be published to itch.io using <a href="https://itch.io/docs/butler/pushing.html" target="_blank">butler</a>. Channel names are derived from package tags.</>,
  'deploy_to_site_dialog.deploy': 'Publish',
  'deploy_to_site_dialog.close': 'Close',
  'deploy_to_site_dialog.error.no_packages': 'No packages to publish.',
  'deploy_to_site_dialog.error.non_unique_channels': (packagesCount, channel) => <>Multiple packages cannot be published into a single channel. Currently {plural(packagesCount, <><strong>{packagesCount}</strong> package</>, <><strong>{packagesCount}</strong> packages</>)} are set to publish in the channel <strong>{channel}</strong>.</>,
  'deploy_to_site_dialog.job.queued': 'Queued',
  'deploy_to_site_dialog.job.downloading': 'Downloading...',
  'deploy_to_site_dialog.job.unpacking': 'Unpacking...',
  'deploy_to_site_dialog.job.uploading': 'Publishing...',
  'deploy_to_site_dialog.job.succeeded': 'Success',
  'deploy_to_site_dialog.job.errored': 'Error',

  'route.store': 'Store',
  'route.store.hint': 'Public projects',
  'store.project.hint': 'Store project page',
  'store.project.owner': 'Developer',
  'store.project.description': 'Description',
  'store.project.content': 'Content',
  'store.project.content.warning': 'The content has been provided by a third-party developer.',
  'store.project.branch_version': (branchTitle, versionTitle) => <><strong>{branchTitle}</strong> branch: version <strong>{versionTitle}</strong></>,
  'store.project.branch_version.published': (branchVersionPublished) => <>Published: <strong>{branchVersionPublished}</strong></>,

  'route.me': 'Profile',
  'route.me.hint': 'Profile settings and linked credentials',
  'me.rename': 'Rename profile',
  'me.rename_dialog.header': 'Rename profile',
  'me.rename_dialog.label_title': 'Name:',
  'me.rename_dialog.ok': 'Rename profile',
  'me.credentials': 'Credentials',
  'me.credentials.hint': 'Any of these credentials can be used to log in into this account.',
  'me.credentials.empty': 'No credentials.',
  'me.credentials.profile_picture.alt': (provider) => `${dict[`auth.provider.${provider}`]} profile picture`,
  'me.credentials.created': (created) => <>added {created}</>,
  'me.credentials.last_used': (lastUsed) => <>last used {lastUsed}</>,
  'me.credential.unlink': 'Unlink',
  'me.credential.link_another': 'Link another',
  'me.credential.link_dialog.header': 'Link credential',
  'me.credential.link_dialog.message': 'Please select your account provider to link with.',
  'me.credential.unlink_dialog.header': 'Unlink credential',
  'me.credential.unlink_dialog.are_you_sure': (credential) => <>Are you sure you want to unlink <strong>{dict[`auth.provider.${credential.provider}`]}</strong> account <strong>{credential.title}</strong> from your {dict['app.name']} account?<br /><br />You will not be able to use it for signing in anymore.</>,
  'me.credential.unlink_dialog.ok': 'Unlink credential',
  'me.sessions': 'Sessions',
  'me.sessions.hint': 'This is a list of your active sessions, including your current browser.',
  'me.sessions.started': (started) => <>started <span class="value">{started}</span></>,
  'me.sessions.last_auth': (lastAuth) => <>active <span class="value">{lastAuth}</span></>,
  'me.sessions.current': 'Current session',
  'me.sessions.empty': 'No sessions.',
  'me.sessions.delete': 'Terminate',
  'me.sessions.delete_dialog.header': 'Terminate session',
  'me.sessions.delete_dialog.message': 'Are you sure you want to terminate this session?',
  'me.sessions.delete_dialog.ok': 'Terminate session',
  'me.api_tokens': 'API tokens',
  'me.api_tokens.hint': 'API tokens can be created here to use in automation.',
  'me.api_tokens.started': (started) => <>created <span class="value">{started}</span></>,
  'me.api_tokens.expires': (expires) => <>expires <span class="value">{expires}</span></>,
  'me.api_tokens.no_expiration': 'no expiration',
  'me.api_tokens.empty': 'No API tokens.',
  'me.api_tokens.create': 'Generate API token',
  'me.api_tokens.create_dialog.header': 'Generate API token',
  'me.api_tokens.create_dialog.message': 'Generated API token:',
  'me.api_tokens.create_dialog.note': 'The token will not be shown again, please save it safely.',
  'me.api_tokens.delete': 'Delete',
  'me.api_tokens.delete_dialog.header': 'Delete API token',
  'me.api_tokens.delete_dialog.message': 'Are you sure you want to delete this token?',
  'me.api_tokens.delete_dialog.ok': 'Delete API token',
  'me.deploy_credentials': 'Deploy credentials',
  'me.deploy_credentials.hint': 'Credentials for deploying to publishing platforms can be added here.',
  'me.deploy_credentials.empty': 'No deploy credentials added.',
  'me.deploy_credential.add': 'Add',
  'me.deploy_credential.add_dialog.header': 'Add deploy credential',
  'me.deploy_credential.add_dialog.label_site': 'Site:',
  'me.deploy_credential.add_dialog.label_title': 'Note:',
  'me.deploy_credential.add_dialog.label_key': 'Key:',
  'me.deploy_credential.add_dialog.default_title': (date) => `Key ${date}`,
  'me.deploy_credential.add_dialog.ok': 'Add deploy credential',
  'me.deploy_credential.add_dialog.hint.steam': <>We do not support adding Steam credentials at the moment.</>,
  'me.deploy_credential.add_dialog.hint.itch': <>To generate the itch.io API key, go to your <a href="https://itch.io/user/settings/api-keys" target="_blank">profile settings</a> on itch.io. You can use existing API key with source of "web" or "wharf". API key with source "Kavykhi.Cloud" will not work.</>,
  'me.deploy_credential.delete': 'Delete',
  'me.deploy_credential.delete_dialog.header': 'Delete deploy credential',
  'me.deploy_credential.delete_dialog.message': (credential) => <>Are you sure you want to delete credential <strong>{credential.title}</strong> for <strong>{dict[`deploy_credential_provider.${credential.provider}`]}</strong>? Any deploy sites configured with this credential will also be removed from the project.</>,
  'me.deploy_credential.delete_dialog.ok': 'Delete credential',
  'me.public_profile': 'Public profile',
  'me.public_profile.public_credentials': 'Public credentials',
  'me.public_profile.public_credentials.hint': 'Select credentials to display publicly.',
  'me.manage': 'Manage account',
  'me.delete': 'Delete account',
  'me.delete.dialog.header': 'Delete account',
  'me.delete.dialog.message': (userTitle) => <>Are you sure you want to irrevocably delete your {dict['app.name']} account <strong>{userTitle}</strong>?</>,
  'me.delete.dialog.ok': 'Delete account',

  'route.invite': 'Invitation',
  'route.invite.hint': 'Project invitation',
  'invite.message.already_member': (projectTitle) => <>You are already a member of the project <strong>{projectTitle}</strong>.</>,
  'invite.message.join': (projectTitle) => <>You have been invited to the project <strong>{projectTitle}</strong>.</>,
  'invite.join': 'Join project',

  'error_dialog.header': 'Error',
  'error_dialog.messageFunc': (e) => `Error: ${dict[`error.${e}`] ?? e}.`,

  'upload_box.choose_files': 'Pick files',
  'upload_box.choose_directories': 'Pick folders',
  'upload_box.add.progress_dialog.header': 'Adding files',
  'upload_box.add.progress_dialog.message': 'Processing files...',
  'upload_box.delete': (itemsCount) => `Delete ${plural(itemsCount, `${itemsCount} item`, `${itemsCount} items`)}`,
  'upload_box.start_upload': 'Start upload',
  'upload_box.drop_area.message': 'Drop files and folders here',
  'upload_box.drop_area.box_dropping_message': 'Drop here to put into root',
  'upload_box.entry.explode_folder': 'Replace folder with its contents',
  'upload_box.entry.mark_executable': 'Mark executable',
  'upload_box.entry.unmark_executable': 'Unmark executable',
  'upload_box.entry.remove': 'Remove',

  'package.tags.empty': '(no tags)',
  'package.tag.os': 'OS',
  'package.tag.os:windows': 'Windows',
  'package.tag.os:linux': 'Linux',
  'package.tag.os:macos': 'macOS',
  'package.tag.os:android': 'Android',
  'package.tag.os:ios': 'iOS',
  'package.tag.os:web': 'Web',
  'package.tag.arch': 'Arch',
  'package.tag.arch:x86': 'x86',
  'package.tag.arch:arm': 'ARM',
  'package.tag.bits': 'Bitness',
  'package.tag.bits:64': '64-bit',
  'package.tag.bits:32': '32-bit',

  'route.docs': 'Docs',
  'docs.page_no_language': 'Not available in this language',
  'docs.page_no_language.message': 'Other versions of this document available:',
  'docs.page_no_language.other_link': (doc, lang) => <>({lang}) {doc}</>,

  'social_links.discord': 'Discord',

  'report_content': 'Report content',
  'report_content.dialog.header': 'Report content',
  'report_content.dialog.link': 'Send report by email',
  'report_content.dialog.message': (email, link) => <>Please click the link to send your report:<br /><br />{link}<br /><br />Or email us manually at <strong>{email}</strong></>,
};

export default dict;

const plural = (n: number, one: string, many: string) => n == 1 ? one : many;
