// Linux key codes (KEY_* from https://github.com/torvalds/linux/blob/master/include/uapi/linux/input-event-codes.h)
const keyboardLinuxKeyCodes: Map<string, number> = new Map(Object.entries({
  KEY_ESC: 1,
  KEY_1: 2,
  KEY_2: 3,
  KEY_3: 4,
  KEY_4: 5,
  KEY_5: 6,
  KEY_6: 7,
  KEY_7: 8,
  KEY_8: 9,
  KEY_9: 10,
  KEY_0: 11,
  KEY_MINUS: 12,
  KEY_EQUAL: 13,
  KEY_BACKSPACE: 14,
  KEY_TAB: 15,
  KEY_Q: 16,
  KEY_W: 17,
  KEY_E: 18,
  KEY_R: 19,
  KEY_T: 20,
  KEY_Y: 21,
  KEY_U: 22,
  KEY_I: 23,
  KEY_O: 24,
  KEY_P: 25,
  KEY_LEFTBRACE: 26,
  KEY_RIGHTBRACE: 27,
  KEY_ENTER: 28,
  KEY_LEFTCTRL: 29,
  KEY_A: 30,
  KEY_S: 31,
  KEY_D: 32,
  KEY_F: 33,
  KEY_G: 34,
  KEY_H: 35,
  KEY_J: 36,
  KEY_K: 37,
  KEY_L: 38,
  KEY_SEMICOLON: 39,
  KEY_APOSTROPHE: 40,
  KEY_GRAVE: 41,
  KEY_LEFTSHIFT: 42,
  KEY_BACKSLASH: 43,
  KEY_Z: 44,
  KEY_X: 45,
  KEY_C: 46,
  KEY_V: 47,
  KEY_B: 48,
  KEY_N: 49,
  KEY_M: 50,
  KEY_COMMA: 51,
  KEY_DOT: 52,
  KEY_SLASH: 53,
  KEY_RIGHTSHIFT: 54,
  KEY_KPASTERISK: 55,
  KEY_LEFTALT: 56,
  KEY_SPACE: 57,
  KEY_CAPSLOCK: 58,
  KEY_F1: 59,
  KEY_F2: 60,
  KEY_F3: 61,
  KEY_F4: 62,
  KEY_F5: 63,
  KEY_F6: 64,
  KEY_F7: 65,
  KEY_F8: 66,
  KEY_F9: 67,
  KEY_F10: 68,
  KEY_NUMLOCK: 69,
  KEY_SCROLLLOCK: 70,
  KEY_KP7: 71,
  KEY_KP8: 72,
  KEY_KP9: 73,
  KEY_KPMINUS: 74,
  KEY_KP4: 75,
  KEY_KP5: 76,
  KEY_KP6: 77,
  KEY_KPPLUS: 78,
  KEY_KP1: 79,
  KEY_KP2: 80,
  KEY_KP3: 81,
  KEY_KP0: 82,
  KEY_KPDOT: 83,

  KEY_ZENKAKUHANKAKU: 85,
  KEY_102ND: 86,
  KEY_F11: 87,
  KEY_F12: 88,
  KEY_RO: 89,
  KEY_KATAKANA: 90,
  KEY_HIRAGANA: 91,
  KEY_HENKAN: 92,
  KEY_KATAKANAHIRAGANA: 93,
  KEY_MUHENKAN: 94,
  KEY_KPJPCOMMA: 95,
  KEY_KPENTER: 96,
  KEY_RIGHTCTRL: 97,
  KEY_KPSLASH: 98,
  KEY_SYSRQ: 99,
  KEY_RIGHTALT: 100,
  KEY_LINEFEED: 101,
  KEY_HOME: 102,
  KEY_UP: 103,
  KEY_PAGEUP: 104,
  KEY_LEFT: 105,
  KEY_RIGHT: 106,
  KEY_END: 107,
  KEY_DOWN: 108,
  KEY_PAGEDOWN: 109,
  KEY_INSERT: 110,
  KEY_DELETE: 111,
  KEY_MACRO: 112,
  KEY_MUTE: 113,
  KEY_VOLUMEDOWN: 114,
  KEY_VOLUMEUP: 115,
  KEY_POWER: 116,
  KEY_KPEQUAL: 117,
  KEY_KPPLUSMINUS: 118,
  KEY_PAUSE: 119,
  KEY_SCALE: 120,

  KEY_KPCOMMA: 121,
  KEY_HANGEUL: 122,
  KEY_HANGUEL: 122,
  KEY_HANJA: 123,
  KEY_YEN: 124,
  KEY_LEFTMETA: 125,
  KEY_RIGHTMETA: 126,
  KEY_COMPOSE: 127,

  KEY_STOP: 128,
  KEY_AGAIN: 129,
  KEY_PROPS: 130,
  KEY_UNDO: 131,
  KEY_FRONT: 132,
  KEY_COPY: 133,
  KEY_OPEN: 134,
  KEY_PASTE: 135,
  KEY_FIND: 136,
  KEY_CUT: 137,
  KEY_HELP: 138,
  KEY_MENU: 139,
  KEY_CALC: 140,
  KEY_SETUP: 141,
  KEY_SLEEP: 142,
  KEY_WAKEUP: 143,
  KEY_FILE: 144,
  KEY_SENDFILE: 145,
  KEY_DELETEFILE: 146,
  KEY_XFER: 147,
  KEY_PROG1: 148,
  KEY_PROG2: 149,
  KEY_WWW: 150,
  KEY_MSDOS: 151,
  KEY_COFFEE: 152,
  KEY_SCREENLOCK: 152,
  KEY_ROTATE_DISPLAY: 153,
  KEY_DIRECTION: 153,
  KEY_CYCLEWINDOWS: 154,
  KEY_MAIL: 155,
  KEY_BOOKMARKS: 156,
  KEY_COMPUTER: 157,
  KEY_BACK: 158,
  KEY_FORWARD: 159,
  KEY_CLOSECD: 160,
  KEY_EJECTCD: 161,
  KEY_EJECTCLOSECD: 162,
  KEY_NEXTSONG: 163,
  KEY_PLAYPAUSE: 164,
  KEY_PREVIOUSSONG: 165,
  KEY_STOPCD: 166,
  KEY_RECORD: 167,
  KEY_REWIND: 168,
  KEY_PHONE: 169,
  KEY_ISO: 170,
  KEY_CONFIG: 171,
  KEY_HOMEPAGE: 172,
  KEY_REFRESH: 173,
  KEY_EXIT: 174,
  KEY_MOVE: 175,
  KEY_EDIT: 176,
  KEY_SCROLLUP: 177,
  KEY_SCROLLDOWN: 178,
  KEY_KPLEFTPAREN: 179,
  KEY_KPRIGHTPAREN: 180,
  KEY_NEW: 181,
  KEY_REDO: 182,

  KEY_F13: 183,
  KEY_F14: 184,
  KEY_F15: 185,
  KEY_F16: 186,
  KEY_F17: 187,
  KEY_F18: 188,
  KEY_F19: 189,
  KEY_F20: 190,
  KEY_F21: 191,
  KEY_F22: 192,
  KEY_F23: 193,
  KEY_F24: 194,

  KEY_PLAYCD: 200,
  KEY_PAUSECD: 201,
  KEY_PROG3: 202,
  KEY_PROG4: 203,
  KEY_ALL_APPLICATIONS: 204,
  KEY_DASHBOARD: 204,
  KEY_SUSPEND: 205,
  KEY_CLOSE: 206,
  KEY_PLAY: 207,
  KEY_FASTFORWARD: 208,
  KEY_BASSBOOST: 209,
  KEY_PRINT: 210,
  KEY_HP: 211,
  KEY_CAMERA: 212,
  KEY_SOUND: 213,
  KEY_QUESTION: 214,
  KEY_EMAIL: 215,
  KEY_CHAT: 216,
  KEY_SEARCH: 217,
  KEY_CONNECT: 218,
  KEY_FINANCE: 219,
  KEY_SPORT: 220,
  KEY_SHOP: 221,
  KEY_ALTERASE: 222,
  KEY_CANCEL: 223,
  KEY_BRIGHTNESSDOWN: 224,
  KEY_BRIGHTNESSUP: 225,
  KEY_MEDIA: 226,

  KEY_SWITCHVIDEOMODE: 227,
  KEY_KBDILLUMTOGGLE: 228,
  KEY_KBDILLUMDOWN: 229,
  KEY_KBDILLUMUP: 230,

  KEY_SEND: 231,
  KEY_REPLY: 232,
  KEY_FORWARDMAIL: 233,
  KEY_SAVE: 234,
  KEY_DOCUMENTS: 235,

  KEY_BATTERY: 236,

  KEY_BLUETOOTH: 237,
  KEY_WLAN: 238,
  KEY_UWB: 239,

  KEY_UNKNOWN: 240,

  KEY_VIDEO_NEXT: 241,
  KEY_VIDEO_PREV: 242,
  KEY_BRIGHTNESS_CYCLE: 243,
  KEY_BRIGHTNESS_AUTO: 244,
  KEY_BRIGHTNESS_ZERO: 244,
  KEY_DISPLAY_OFF: 245,

  KEY_WWAN: 246,
  KEY_WIMAX: 246,
  KEY_RFKILL: 247,

  KEY_MICMUTE: 248,
}));

const keyboardEventCodeToLinuxKeyIds = {
  // alphanumeric session
  // writing system keys
  Backquote: 'KEY_GRAVE',
  Backslash: 'KEY_BACKSLASH',
  BracketLeft: 'KEY_LEFTBRACE',
  BracketRight: 'KEY_RIGHTBRACE',
  Comma: 'KEY_COMMA',
  Digit0: 'KEY_0',
  Digit1: 'KEY_1',
  Digit2: 'KEY_2',
  Digit3: 'KEY_3',
  Digit4: 'KEY_4',
  Digit5: 'KEY_5',
  Digit6: 'KEY_6',
  Digit7: 'KEY_7',
  Digit8: 'KEY_8',
  Digit9: 'KEY_9',
  Equal: 'KEY_EQUAL',
  // IntlBackslash: '',
  // IntlRo: '',
  // IntlYen: '',
  KeyA: 'KEY_A',
  KeyB: 'KEY_B',
  KeyC: 'KEY_C',
  KeyD: 'KEY_D',
  KeyE: 'KEY_E',
  KeyF: 'KEY_F',
  KeyG: 'KEY_G',
  KeyH: 'KEY_H',
  KeyI: 'KEY_I',
  KeyJ: 'KEY_J',
  KeyK: 'KEY_K',
  KeyL: 'KEY_L',
  KeyM: 'KEY_M',
  KeyN: 'KEY_N',
  KeyO: 'KEY_O',
  KeyP: 'KEY_P',
  KeyQ: 'KEY_Q',
  KeyR: 'KEY_R',
  KeyS: 'KEY_S',
  KeyT: 'KEY_T',
  KeyU: 'KEY_U',
  KeyV: 'KEY_V',
  KeyW: 'KEY_W',
  KeyX: 'KEY_X',
  KeyY: 'KEY_Y',
  KeyZ: 'KEY_Z',
  Minus: 'KEY_MINUS',
  Period: 'KEY_DOT',
  Quote: 'KEY_APOSTROPHE',
  Semicolon: 'KEY_SEMICOLON',
  Slash: 'KEY_SLASH',
  // functional keys
  AltLeft: 'KEY_LEFTALT',
  AltRight: 'KEY_RIGHTALT',
  Backspace: 'KEY_BACKSPACE',
  CapsLock: 'KEY_CAPSLOCK',
  ContextMenu: 'KEY_MENU',
  ControlLeft: 'KEY_LEFTCTRL',
  ControlRight: 'KEY_RIGHTCTRL',
  Enter: 'KEY_ENTER',
  MetaLeft: 'KEY_LEFTMETA',
  MetaRight: 'KEY_RIGHTMETA',
  ShiftLeft: 'KEY_LEFTSHIFT',
  ShiftRight: 'KEY_RIGHTSHIFT',
  Space: 'KEY_SPACE',
  Tab: 'KEY_TAB',

  // control pad section
  Delete: 'KEY_DELETE',
  End: 'KEY_END',
  Help: 'KEY_HELP',
  Home: 'KEY_HOME',
  Insert: 'KEY_INSERT',
  PageDown: 'KEY_PAGEDOWN',
  PageUp: 'KEY_PAGEUP',

  // arrow pad section
  ArrowDown: 'KEY_DOWN',
  ArrowLeft: 'KEY_LEFT',
  ArrowRight: 'KEY_RIGHT',
  ArrowUp: 'KEY_UP',

  // numpad section
  NumLock: 'KEY_NUMLOCK',
  Numpad0: 'KEY_KP0',
  Numpad1: 'KEY_KP1',
  Numpad2: 'KEY_KP2',
  Numpad3: 'KEY_KP3',
  Numpad4: 'KEY_KP4',
  Numpad5: 'KEY_KP5',
  Numpad6: 'KEY_KP6',
  Numpad7: 'KEY_KP7',
  Numpad8: 'KEY_KP8',
  Numpad9: 'KEY_KP9',
  NumpadAdd: 'KEY_KPPLUS',
  // NumpadBackspace: '',
  // NumpadClear: '',
  // NumpadClearEntry: '',
  NumpadComma: 'KEY_KPCOMMA',
  NumpadDecimal: 'KEY_KPDOT',
  NumpadDivide: 'KEY_KPSLASH',
  NumpadEnter: 'KEY_KPENTER',
  NumpadEqual: 'KEY_KPEQUAL',
  // NumpadHash: '',
  // NumpadMemoryAdd: '',
  // NumpadMemoryClear: '',
  // NumpadMemoryRecall: '',
  // NumpadMemoryStore: '',
  // NumpadMemorySubtract: '',
  NumpadMultiply: 'KEY_KPASTERISK',
  NumpadParenLeft: 'KEY_KPLEFTPAREN',
  NumpadParenRight: 'KEY_KPRIGHTPAREN',
  // NumpadStar: '',
  NumpadSubtract: 'KEY_KPMINUS',

  // function section
  Escape: 'KEY_ESC',
  F1: 'KEY_1',
  F2: 'KEY_2',
  F3: 'KEY_3',
  F4: 'KEY_4',
  F5: 'KEY_5',
  F6: 'KEY_6',
  F7: 'KEY_7',
  F8: 'KEY_8',
  F9: 'KEY_9',
  F10: 'KEY_10',
  F11: 'KEY_11',
  F12: 'KEY_12',
  // Fn: '',
  // FnLock: '',
  PrintScreen: 'KEY_PRINT',
  ScrollLock: 'KEY_SCROLLLOCK',
  Pause: 'KEY_PAUSE',
};

export const keyboardEventKeyCodesToLinuxKeyCodes: Map<string, number | undefined> = new Map(
  Object.entries(keyboardEventCodeToLinuxKeyIds)
  .map(([code, linuxKeyId]) => [code, keyboardLinuxKeyCodes.get(linuxKeyId)])
);
