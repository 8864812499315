import {
  Component,
  createMemo,
  JSXElement,
  Show,
  useContext,
} from 'solid-js';
import {
  linkProjectBranchVersionPackageCloudPlay,
  linkProjectBranchVersionPackagePlay,
} from '../app/routes/links';
import { AuthContext } from '../components';
import config from '../config';
import { showErrorScope } from '../dialogs/basic';
import { BranchInfo, PackageInfo, ProjectInfo, VersionInfo } from './api-generated';
import { Button, Dropdown, FaIcon } from './basic';
import { LocalizationContext } from './localization';
import { useRoutingNavigate } from './routing';

type Action = {
  title: JSXElement;
  onAction: () => void;
};

export const PackageActionButton: Component<{
  project: ProjectInfo;
  branch?: BranchInfo;
  version?: VersionInfo;
  pkg: PackageInfo;
}> = (props) => {
  const { api, session } = useContext(AuthContext)!;
  const { t } = useContext(LocalizationContext)!;
  const navigate = useRoutingNavigate();

  const actions = createMemo(() => {
    const actions: Action[] = [];
    if(props.pkg.tags.indexOf('os:web') >= 0) {
      actions.push({
        title: <><FaIcon solid play weak inline />{t('packages.play')}</>,
        onAction: () => {
          navigate(linkProjectBranchVersionPackagePlay({
            projectId: props.project.id,
            branchId: props.branch?.id,
            versionId: props.version?.id,
            packageId: props.pkg.id,
          }));
        },
      });
    }
    if((session()?.userPrivileges ?? []).indexOf('cloud_play') >= 0 && props.pkg.tags.indexOf('os:linux') >= 0 && props.pkg.tags.indexOf('arch:x86') >= 0) {
      actions.push({
        title: <><FaIcon solid play weak inline />{t('packages.cloud_play')}</>,
        onAction: () => {
          navigate(linkProjectBranchVersionPackageCloudPlay({
            projectId: props.project.id,
            branchId: props.branch?.id,
            versionId: props.version?.id,
            packageId: props.pkg.id,
          }));
        },
      });
    }
    actions.push({
      title: <><FaIcon solid file-zipper weak inline />{t('packages.download_zip')}</>,
      onAction: () => showErrorScope(async () => {
        const url = new URL(await api.getProjectsPackagesDownload({
          project: props.project.id,
          _package: props.pkg.id,
        }), config.storageBaseUrl);
        url.searchParams.set('filter', 'zip');
        const link = document.createElement('a');
        link.href = url.toString();
        link.download = `${props.project.title} ${props.pkg.title}.zip`;
        link.click();
      }),
    });
    actions.push({
      title: <><FaIcon solid magnet weak inline />{t('packages.download_torrent')}</>,
      onAction: () => showErrorScope(async () => {
        const url = new URL(await api.getProjectsPackagesTorrent({
          project: props.project.id,
          _package: props.pkg.id,
        }), config.storageBaseUrl);
        const link = document.createElement('a');
        link.href = url.toString();
        link.download = `${props.project.title} ${props.pkg.title}.torrent`;
        link.click();
      }),
    });
    return actions;
  });

  return <>
    <Button onClick={actions()[0].onAction}>{actions()[0].title}</Button>
    <Show when={actions().length > 1}>
      <Dropdown title="" items={actions().slice(1).map(({ title, onAction }) => ({
        key: onAction,
        title,
      }))} onSelect={(onAction: () => void) => onAction()} alignEnd />
    </Show>
  </>;
};
