import {
  Accessor,
  Component,
  For,
  createSignal,
  useContext,
} from 'solid-js';
import {
  AppPage,
  AuthContext,
  Button,
  JobState,
} from '../../components';
import config from '../../config';
import { watchJobs } from '../../components/jobs';

export const RouteExperiment: Component = () => {
  const { api } = useContext(AuthContext)!;
  const [jobs, setJobs] = createSignal<Accessor<JobState>[]>([]);

  return <>
    <AppPage
      title="Experiment"
      breadcrumb="Experiment"
      uiClass="experiment"
    />
    <div class="footer">
      <div class="buttons align_end">
        <Button onClick={async () => {
          const tokens = [];
          for(let i = 0; i < 5; ++i)
            tokens.push(await api.postJobs());
          setJobs(await watchJobs(tokens));
        }}>Test</Button>
      </div>
      <div>
        <For each={jobs()}>{(job) =>
          <div>
            <div>{JSON.stringify(job().output)}</div>
            <div>{JSON.stringify(job().progress)}</div>
          </div>
        }</For>
      </div>
    </div>
  </>;
};
