import {
  Set as ISet,
} from 'immutable';
import {
  Component,
  createSignal,
  useContext,
} from 'solid-js';
import {
  AuthContext,
  Button,
  DialogParams,
  LocalizationContext,
  Tags,
  TagsEditor,
} from '../components';
import {
  PackageInfo,
} from '../components/api-generated';

export const EditPackageTagsDialog: Component<DialogParams<ISet<string>> & {
  pkg: PackageInfo;
}> = (props) => {
  const { t } = useContext(LocalizationContext)!;
  const { api } = useContext(AuthContext)!;

  const [tags, setTags] = createSignal(ISet(props.pkg.tags));

  return (
    <props.dialog class="sheet ui big edit_package_tags">
      <h1>{t('packages.edit_tags_dialog.header')}</h1>
      <p>{t('packages.edit_tags_dialog.message')(props.pkg.title)}</p>
      <TagsEditor tags={tags} setTags={setTags} />
      <Tags tags={tags()} />
      <div class="footer">
        <div class="buttons align_end">
          <Button onClick={() => props.resolve(tags())}>{t('packages.edit_tags_dialog.ok')}</Button>
          <Button onClick={() => props.resolve()}>{t('cancel')}</Button>
        </div>
      </div>
    </props.dialog>
  );
};
