import {
  Component,
  Show,
  createMemo,
  createResource,
  createSignal,
  useContext,
} from "solid-js";
import {
  AuthContext,
  Button,
  DeployProviderIcon,
  DialogParams,
  Dropdown,
  LocalizationContext,
  ProviderImage,
  ResourceFallback,
  callDialog,
  deployProvidersInfos,
} from "../components";
import {
  DeployCredentialInfo,
  ProjectInfo,
} from "../components/api-generated";
import {
  AddDeployCredentialDialog,
} from "./add_deploy_credential";
import {
  showErrorScope,
} from "./basic";

export const AddDeploySiteDialog: Component<DialogParams<string | null> & {
  project: ProjectInfo;
}> = (props) => {
  const { t } = useContext(LocalizationContext)!;
  const { api } = useContext(AuthContext)!;

  const [deployCredentials, { refetch: refetchDeployCredentials }] = createResource<DeployCredentialInfo[]>(async () => {
    return await api.getUsersMeDeployCredentials();
  });

  const [selectedDeployCredential, setSelectedDeployCredential] = createSignal<DeployCredentialInfo | null>(null);

  const deployCredentialTitle = (deployCredential: DeployCredentialInfo | null) => deployCredential && <>
    <DeployProviderIcon provider={deployCredential.provider} />
    <span>{deployCredential.title}</span>
  </>;

  const onSelectDeployCredential = async (deployCredential: DeployCredentialInfo | null) => {
    if(deployCredential) {
      setSelectedDeployCredential((currentDeployCredential) => {
        // reset site on credential change
        if(currentDeployCredential != deployCredential) {
          setSiteURL('');
        }
        return deployCredential;
      });
    } else {
      if(!await callDialog(AddDeployCredentialDialog, {})) return;
      refetchDeployCredentials();
    }
  };

  const [deployCredentialAvailableSites] = createResource(selectedDeployCredential, async (deployCredential) => {
    if(!deployCredential) return null;
    return Object.fromEntries((await api.getUsersMeDeployCredentialsAvailableSites({
      deployCredential: deployCredential.id,
    })).map((site) => [site.id, site]));
  });

  const [siteURL, setSiteURL] = createSignal('');

  const selectedAvailableSite = createMemo(() => (deployCredentialAvailableSites() ?? {})[siteURL()]);

  const info = createMemo<{
    placeholder: string;
    site: string | null;
  } | null>(() => {
    const info = deployProvidersInfos[selectedDeployCredential()?.provider ?? ''];
    if(!info) return null;
    return {
      placeholder: info.siteInputPlaceholder,
      site: info.fromSiteInput(siteURL()),
    };
  });

  return (
    <props.dialog class="sheet ui small add_deploy_site">
      <h1>{t('deploy_sites.add_dialog.header')}</h1>
      <div class="message">{t('deploy_sites.add_dialog.message')(props.project.title)}</div>
      <label>
        <div class="label">{t('deploy_sites.add_dialog.label_credential')}</div>
        <Dropdown classList={{
          value: true,
        }} title={deployCredentialTitle(selectedDeployCredential()) ?? t('deploy_sites.add_dialog.select_credential')} items={[...(deployCredentials()?.map((deployCredential) => ({
          key: deployCredential,
          title: deployCredentialTitle(deployCredential),
        })) ?? []), {
          key: null,
          title: t('deploy_sites.add_dialog.select_credential.new'),
        }]} onSelect={onSelectDeployCredential} />
      </label>
      <label>
        <div class="label">{t('deploy_sites.add_dialog.label_select_site')}</div>
        <Dropdown
          classList={{
            value: true,
          }}
          menuClassList={{
            available_deploy_sites: true,
          }}
          title={selectedAvailableSite()?.title ?? (selectedDeployCredential() ? t('deploy_sites.add_dialog.select_site') : t('deploy_sites.add_dialog.select_credential_first'))}
          items={Object.entries(deployCredentialAvailableSites() ?? {}).sort(([_a, a], [_b, b]) => new Intl.Collator(t('locale')).compare(a.title, b.title)).map(([_siteId, site]) => ({
            key: site.id,
            title: <>
              <Show when={site.pictureUrl}>
                <ProviderImage class="picture" provider={selectedDeployCredential()!.provider} url={site.pictureUrl!} />
              </Show>
              <div class="title">{site.title}</div>
              <div class="url">{site.id}</div>
            </>,
          })) || []}
          fallback={<ResourceFallback resource={deployCredentialAvailableSites} />}
          onSelect={(siteId: string) => setSiteURL(siteId)}
          disabled={!selectedDeployCredential()}
        />
      </label>
      <label>
        <div class="label">{t('deploy_sites.add_dialog.label_enter_site')}</div>
        <div class="value">
          <input class="value" type="text" value={siteURL()} onInput={(e) => {
            setSiteURL(e.target.value);
          }} placeholder={info()?.placeholder} />
        </div>
      </label>
      <div class="footer">
        <div class="buttons align_end">
          <Button disabled={selectedDeployCredential() == null || !info()?.site} onClick={async () => {
            await showErrorScope(async () => {
              const deploySiteId = await api.postProjectsDeploySites({
                project: props.project.id,
                requestBody: {
                  credential: selectedDeployCredential()!.id,
                  site: info()!.site!,
                },
              });
              props.resolve(deploySiteId);
            });
          }}>{t('deploy_sites.add_dialog.ok')}</Button>
          <Button onClick={() => props.resolve()} autofocus>{t('cancel')}</Button>
        </div>
      </div>
    </props.dialog>
  );
};
