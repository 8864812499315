import {
  Component,
  For,
  createResource,
  createSignal,
  useContext,
} from "solid-js";
import {
  AuthContext,
  Button,
  DialogParams,
  FormatDate,
  LocalizationContext,
  ResourceFallback,
} from "../components";
import {
  ProjectInfo,
  VersionInfo,
} from "../components/api-generated";

export const PublishExistingVersionDialog: Component<DialogParams<string | null> & {
  project: ProjectInfo;
  currentVersion?: VersionInfo;
}> = (props) => {
  const { t } = useContext(LocalizationContext)!;
  const { api } = useContext(AuthContext)!;

  const [versions, { refetch: refetchVersions }] = createResource<VersionInfo[]>(async () => {
    return await api.getProjectsVersions({
      project: props.project.id,
    });
  });

  const [selectedVersion, setSelectedVersion] = createSignal<string | null>(null);

  const onItemClick = (version: VersionInfo, e: MouseEvent) => {
    setSelectedVersion(version.id);
  };

  return (
    <props.dialog class="sheet ui medium select_existing_version">
      <h1>{t('versions.publish_existing_dialog.header')}</h1>
      <div class="message">{t('versions.publish_existing_dialog.message')}</div>
      <div class="list versions">
        <For each={versions()} fallback={
          <div>
            <ResourceFallback resource={versions} empty={<div class="empty">{t('versions.publish_existing_dialog.no_versions')}</div>} />
          </div>
        }>{(version) =>
          <div classList={{
            link: props.currentVersion == null || version.id != props.currentVersion.id,
            current: version.id == props.currentVersion?.id,
            selected: selectedVersion() === version.id,
          }} onClick={props.currentVersion == null || version.id != props.currentVersion.id ? [onItemClick, version] : undefined}>
            <div class="title">{version.title}</div>
            <div class="created"><FormatDate date={new Date(version.created)} /></div>
          </div>
        }</For>
      </div>
      <div class="footer">
        <div class="buttons align_end">
          <Button disabled={selectedVersion() == null} onClick={() => props.resolve(selectedVersion())}>{t('versions.publish_existing_dialog.ok')}</Button>
          <Button onClick={() => props.resolve()}>{t('cancel')}</Button>
        </div>
      </div>
    </props.dialog>
  );
};
