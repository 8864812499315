import {
  Accessor,
  Component,
  Setter,
} from 'solid-js';
import { Dynamic } from 'solid-js/web';
import Markdown from 'solid-marked/component';

export type MarkdownViewConfig = {
  text: Accessor<string>;
  level?: number;
  ugc?: boolean;
  linkInNewTab?: boolean;
};

export const MarkdownView: Component<MarkdownViewConfig> = (props) =>
  <Markdown builtins={{
    Blockquote: (p) => <blockquote>{p.children}</blockquote>,
    Break: () => <br />,
    Code: (p) => <div class="code"><pre>{p.children}</pre></div>,
    Delete: (p) => <s>{p.children}</s>,
    Emphasis: (p) => <em>{p.children}</em>,
    Heading: (p) => <Dynamic component={`h${p.depth + (props.level ?? 0)}`}>{p.children}</Dynamic>,
    InlineCode: (p) => <code>{p.children}</code>,
    Link: (p) => <a href={p.url} title={p.title ?? undefined} rel={(props.ugc ?? true) ? 'ugc nofollow' : undefined} target={props.linkInNewTab ? '_blank' : undefined}>{p.children}</a>,
    List: (p) => p.ordered ? <ol>{p.children}</ol> : <ul>{p.children}</ul>,
    ListItem: (p) => <li>{p.children}</li>,
    Paragraph: (p) => <p>{p.children}</p>,
    Root: (p) => <div class="markdown">{p.children}</div>,
    Strong: (p) => <strong>{p.children}</strong>,
  }}>{props.text()}</Markdown>
;

export const MarkdownEditor: Component<MarkdownViewConfig & {
  setText: Setter<string>;
}> = (props) => {
  let ref: HTMLTextAreaElement;
  return <>
    <textarea ref={ref!} value={props.text()} onInput={(e) => {
      e.preventDefault();
      e.stopPropagation();
      props.setText(ref.value);
    }} rows={10} />
    <MarkdownView {...props} />
  </>;
};
