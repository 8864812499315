import {
  List as IList,
  Set as ISet,
} from 'immutable';
import {
  Accessor,
  Component,
  For,
  Setter,
  Show,
  createEffect,
  createMemo,
  createSignal,
  onMount,
  useContext
} from 'solid-js';
import {
  ArchiveChunksIndex,
  AuthContext,
  Button,
  ChunkingAlgorithm,
  CompressionAlgorithm,
  DialogParams,
  DirectoryEntry,
  FaIcon,
  JobState,
  JobsWatcher,
  LocalizationContext,
  PrettyDataSize,
  Tags,
  buffer2hex,
  callDialog,
  makeSemaphore,
  packEntriesIntoArchive,
  readEntriesFromArchive,
} from '../components';
import {
  BranchInfo,
  ProjectInfo,
  ProjectQuotasInfo,
  VersionInfo,
} from '../components/api-generated';
import config from '../config';
import {
  AddExistingPackageDialog,
} from './add_existing_package';
import {
  showErrorScope,
} from './basic';
import {
  NewPackageDialog,
} from './new_package';

type Package = {
  state: Accessor<PackageState>;
  setState: Setter<PackageState>;
};

type PackageState = {
  id?: string;
  title: string;
  tags: ISet<string>;
  size?: number;
  root?: DirectoryEntry;
  status: PackageStatus;
  parts: PackagePart[];
  resolving?: Accessor<JobState>;
};

type PackageStatus
  = 'existing'
  | 'queued'
  | 'packing'
  | 'creating' | 'create_retrying'
  | 'splitting'
  | 'uploading'
  | 'finalizing' | 'finalize_retrying'
  | 'resolving' | 'resolve_retrying'
  | 'done' | 'errored';

type PackagePart = {
  blob: Blob;
  size: number;
  state: Accessor<PackagePartState>;
  setState: Setter<PackagePartState>;
};

type PackagePartState = {
  status: PackagePartStatus;
  progress: number;
  total: number;
};

type PackagePartStatus = 'queued' | 'hashing'
  | 'upload_queued' | 'uploading' | 'upload_retrying' | 'upload_finalizing' | 'upload_errored'
  | 'done';

export const NewVersionDialog: Component<DialogParams<string | null> & {
  project: ProjectInfo;
  projectQuotas: ProjectQuotasInfo;
  branch: BranchInfo;
  baseVersion: VersionInfo | null;
  initialVersionTitle: string;
}> = (props) => {
  const { t } = useContext(LocalizationContext)!;
  const { api } = useContext(AuthContext)!;

  const [packages, setPackages] = createSignal(IList<Package>());
  const acquirePackageSemaphore = makeSemaphore(2); // covers all work on an package

  let refTextBoxTitle: HTMLInputElement | undefined;
  onMount(() => {
    // set initial version title
    if(props.initialVersionTitle) {
      refTextBoxTitle!.value = props.initialVersionTitle;
    }

    // add base version's packages
    (async () => {
      if(props.baseVersion) {
        const baseVersionPackages = await api.getProjectsVersionsPackages({
          project: props.project.id,
          version: props.baseVersion.id,
        });
        setPackages((packages) => packages.withMutations((packages) => {
          for(const packageInfo of baseVersionPackages) {
            const [state, setState] = createSignal<PackageState>({
              id: packageInfo.id,
              title: packageInfo.title,
              tags: ISet(packageInfo.tags),
              size: packageInfo.size,
              status: 'existing',
              parts: [],
            });
            packages.push({
              state, setState,
            });
            (async () => {
              const url = new URL(await api.getProjectsPackagesDownload({
                project: props.project.id,
                _package: packageInfo.id,
              }), config.storageBaseUrl);
              url.searchParams.set('filter', 'index');
              const root = await readEntriesFromArchive(url.toString());
              setState((state) => ({
                ...state,
                root,
              }));
            })();
          }
        }));
      }
    })();
  });

  const jobsWatcher = new JobsWatcher();

  const uploadPackage = async (pkg: Package, {
    root,
    chunkingAlgorithm,
    compressionAlgorithm,
    baseChunksIndex,
  }: {
    root: DirectoryEntry;
    chunkingAlgorithm: ChunkingAlgorithm;
    compressionAlgorithm: CompressionAlgorithm;
    baseChunksIndex?: ArchiveChunksIndex;
  }, basePackageId?: string) => {
    const setPackageStatus = (status: PackageStatus) => pkg.setState((state) => ({
      ...state,
      status,
    }));

    const freePackageSemaphore = await acquirePackageSemaphore();
    try {
      setPackageStatus('packing');

      const blob = await packEntriesIntoArchive(root, chunkingAlgorithm, compressionAlgorithm, baseChunksIndex);

      const {
        id: packageId,
        token: packageToken,
        uploadUrl,
        partSize,
        partHashAlgorithm,
      } = await tryFewTimes(async () => {
        setPackageStatus('creating');

        const newPackage = await api.postProjectsPackages({
          project: props.project.id,
          requestBody: {
            size: blob.size,
            title: pkg.state().title,
            tags: pkg.state().tags.toArray().sort(),
            basePackage: basePackageId,
          },
        });

        newPackage.uploadUrl = new URL(newPackage.uploadUrl, config.storageBaseUrl).toString();

        return newPackage;
      }, async () => {
        setPackageStatus('create_retrying');
      });

      pkg.setState((state) => ({
        ...state,
        id: packageId,
      }));

      setPackageStatus('splitting');

      const parts: PackagePart[] = [];
      {
        for(let i = 0; i < blob.size; i += partSize) {
          const size = Math.min(partSize, blob.size - i);
          const [state, setState] = createSignal<PackagePartState>({
            status: 'queued',
            progress: 0,
            total: size,
          });
          parts.push({
            blob: blob.slice(i, i + size),
            size,
            state, setState,
          });
        }
      }

      pkg.setState((state) => ({
        ...state,
        parts,
      }));

      setPackageStatus('uploading');

      const acquirePartSemaphore = makeSemaphore(3); // covers all work on a part
      const acquireUploadSemaphore = makeSemaphore(2); // covers actual uploading (without waiting for response)

      const promises = new Array<Promise<void>>(parts.length);
      const partsTokens = new Array(parts.length);
      for(let partIndex = 0; partIndex < parts.length; ++partIndex) {
        promises[partIndex] = (async (partIndex, freePartSemaphore, freeUploadSemaphore) => {
          try {
            const part = parts[partIndex];

            const setPartStatus = (status: PackagePartStatus) => part.setState((state) => ({
              ...state,
              status,
            }));

            setPartStatus('hashing');
            let hash: string;
            switch(partHashAlgorithm) {
            case 'sha1':
              hash = buffer2hex(await crypto.subtle.digest('SHA-1', await part.blob.arrayBuffer()));
              break;
            default:
              throw 'unsupported_hash_algorithm';
            }

            await tryFewTimes(async () => {
              setPartStatus('upload_queued');
              let isFinished = false;
              try {
                setPartStatus('uploading');

                const uploadFinished = () => {
                  freeUploadSemaphore();
                  if(!isFinished) {
                    setPartStatus('upload_finalizing');
                  }
                };

                await new Promise<void>((resolve, reject) => {
                  // use XHR instead of fetch because it supports upload progress
                  const xhr = new XMLHttpRequest();

                  xhr.responseType = 'json';

                  xhr.addEventListener('error', reject);
                  xhr.addEventListener('load', () => {
                    if(xhr.status !== 200) return reject(xhr.response);
                    partsTokens[partIndex] = xhr.response;
                    resolve();
                  });
                  xhr.upload.addEventListener('progress', (e) => {
                    if(e.lengthComputable) {
                      const progress = Number(e.loaded);
                      const total = Number(e.total);
                      part.setState((state) => ({
                        ...state,
                        progress,
                        total,
                      }));
                      if(progress >= total) {
                        // not every browser sends upload.load event (notably Firefox)
                        // so deduce finished upload from progress
                        uploadFinished();
                      }
                    }
                  });
                  xhr.upload.addEventListener('load', uploadFinished);

                  xhr.open('PUT', (() => {
                    const url = new URL(uploadUrl);
                    url.searchParams.set('part', String(partIndex));
                    url.searchParams.set('hash', hash);
                    return url.toString();
                  })(), true);
                  xhr.setRequestHeader('Content-Type', 'application/octet-stream');
                  // no Content-Length header, it's forbidden in browsers
                  xhr.send(part.blob);
                });

                isFinished = true;
                uploadFinished();
              }
              finally {
                freeUploadSemaphore();
              }
            }, async () => {
              setPartStatus('upload_retrying');
            });

            part.setState((state) => ({
              ...state,
              status: 'done',
              progress: state.total, // just in case
            }));
          }
          finally {
            freePartSemaphore();
          }
        })(partIndex, await acquirePartSemaphore(), await acquireUploadSemaphore());
      }

      for(const promise of promises) {
        await promise;
      }

      await tryFewTimes(async () => {
        setPackageStatus('finalizing');
        await api.putProjectsPackagesFinalize({
          project: props.project.id,
          _package: packageId,
          requestBody: {
            token: packageToken,
            parts: partsTokens,
          },
        });
      }, async () => {
        setPackageStatus('finalize_retrying');
      });

      if(basePackageId) {
        const jobToken = await tryFewTimes(async () => {
          setPackageStatus('resolving');
          return await api.postProjectsPackagesResolve({
            project: props.project.id,
            _package: packageId,
          });
        }, async() => {
          setPackageStatus('resolve_retrying');
        });
        const jobState = jobsWatcher.add(jobToken);
        pkg.setState((state) => ({
          ...state,
          resolving: jobState,
        }));

        const resolvedPackageId = await new Promise<string>((resolve, reject) => {
          createEffect(() => {
            const output = jobState().output;
            if(output !== undefined) {
              if(output?.package) resolve(output.package);
              else reject();
            }
          });
        });

        pkg.setState((state) => ({
          ...state,
          id: resolvedPackageId,
          root,
        }));
      }

      setPackageStatus('done');
    }
    catch(e) {
      setPackageStatus('errored');
      throw e;
    }
    finally {
      freePackageSemaphore();
    }
  };

  const onNewPackage = async () => {
    const newPackage = await callDialog(NewPackageDialog, {
      project: props.project,
      projectQuotas: props.projectQuotas,
      version: refTextBoxTitle!.value,
    });
    if(!newPackage) return;

    const [state, setState] = createSignal<PackageState>({
      title: newPackage.title,
      tags: newPackage.tags,
      size: newPackage.root.totalSize(),
      status: 'queued',
      parts: [],
    });

    setPackages((packages) => {
      const pkg: Package = {
        state, setState,
      };
      uploadPackage(pkg, newPackage);
      return packages.push(pkg);
    });
  };

  const newPackageWithBase = async (pkg: Package, keepFiles: boolean) => {
    const basePackageId = pkg.state().id;
    const newPackage = await callDialog(NewPackageDialog, {
      project: props.project,
      projectQuotas: props.projectQuotas,
      version: refTextBoxTitle!.value,
      baseRoot: pkg.state().root,
      cloneFilesFromBase: keepFiles,
      tags: pkg.state().tags,
    });
    if(!newPackage) return;

    pkg.setState((state) => ({
      ...state,
      title: newPackage.title,
      tags: newPackage.tags,
      size: newPackage.root.totalSize(),
      status: 'queued',
      parts: [],
    }));

    uploadPackage(pkg, newPackage, basePackageId);
  };

  const onReplacePackage = (pkg: Package) => newPackageWithBase(pkg, false);
  const onEditPackage = async (pkg: Package) => newPackageWithBase(pkg, true);

  const onAddExistingPackage = async () => {
    const packageInfos = await callDialog(AddExistingPackageDialog, {
      project: props.project,
    });
    if(!packageInfos) return;
    setPackages((packages) => packages.withMutations((packages) => {
      for(const packageInfo of packageInfos) {
        const [state, setState] = createSignal<PackageState>({
          id: packageInfo.id,
          title: packageInfo.title,
          tags: ISet(packageInfo.tags),
          size: packageInfo.size,
          status: 'existing',
          parts: [],
        });
        packages.push({
          state, setState,
        });
      }
    }));
  };

  const onDeletePackage = (index: number) => {
    setPackages((packages) => packages.delete(index));
  };

  const cancelAllUploads = () => {
  };

  const packageInProgress = (pkg: Package) => {
    switch(pkg.state().status) {
    case 'existing':
    case 'done':
    case 'errored':
      return false;
    default:
      return true;
    }
  };
  const packagesDone = createMemo<boolean>(() => packages().every((pkg) => {
    switch(pkg.state().status) {
    case 'existing':
    case 'done':
      return true;
    default:
      return false;
    }
  }));

  const onCreateVersion = async () => {
    await showErrorScope(async () => {
      const versionId = await api.postProjectsVersions({
        project: props.project.id,
        requestBody: {
          title: refTextBoxTitle!.value,
          packages: packages().map((pkg) => pkg.state().id!).toArray(),
        },
      });
      props.resolve(versionId);
    });
  };

  return (
    <props.dialog class="sheet ui big new_version">
      <h1>{t('versions.new_dialog.header')}</h1>
      <label class="version">
        <div class="label">{t('versions.new_dialog.label_title')}</div>
        <div class="value"><input type="text" autofocus ref={refTextBoxTitle} /></div>
      </label>
      <label class="branch">
        <div class="label">{t('versions.new_dialog.label_branch')}</div>
        <div class="value"><strong>{props.branch.title}</strong></div>
      </label>
      <h2>{t('versions.new_dialog.packages.header')}</h2>
      <div class="buttons">
        <Button onClick={onNewPackage}>{t('versions.new_dialog.packages.add')}</Button>
        <Button onClick={onAddExistingPackage}>{t('versions.new_dialog.packages.add_existing')}</Button>
      </div>
      <div class="list packages">
        <For each={packages().toArray()} fallback={
          <div>
            <div class="empty">{t('versions.new_dialog.packages.empty')}</div>
          </div>
        }>{(pkg, index) =>
          <div>
            <div class="title">{pkg.state().title}</div>
            <Tags tags={ISet(pkg.state().tags)} />
            <Show when={pkg.state().size != null}>
              <PrettyDataSize size={pkg.state().size!} />
            </Show>
            <Button light danger onClick={() => onDeletePackage(index())}>
              <FaIcon solid trash-can />
            </Button>
            <Show when={pkg.state().status == 'uploading'} fallback={
              <div class="status">{t(`packages.upload.status.${pkg.state().status}`)}</div>
            }>
              <PackagePartProgress parts={() => pkg.state().parts.map((part) => part.state())} />
            </Show>
            <Show when={!packageInProgress(pkg)}>
              <div class="buttons">
                <Show when={pkg.state().root} fallback={<div class="note">{t('loading')}</div>}>
                  <Button onClick={() => onReplacePackage(pkg)}>{t('versions.new_dialog.packages.replace')}</Button>
                  <Button onClick={() => onEditPackage(pkg)}>{t('versions.new_dialog.packages.edit')}</Button>
                </Show>
              </div>
            </Show>
          </div>
        }</For>
      </div>
      <div class="footer">
        <div class="buttons align_end">
          <Button type="submit" disabled={!packagesDone()} onClick={onCreateVersion}>{t('versions.new_dialog.ok')}</Button>
          <Button onClick={() => {
            cancelAllUploads();
            props.resolve();
          }}>{t('cancel')}</Button>
        </div>
      </div>
    </props.dialog>
  );
};

const tryFewTimes = async <T,>(work: () => Promise<T>, beforeRetry?: () => Promise<void>): Promise<T> => {
  const errors: any[] = [];
  for(let i = 0; i < 3; ++i) {
    try {
      return await work();
    } catch(e) {
      errors.push(e);
      console.log('tryFewTimes error', i + 1, e);
    }
    if(beforeRetry) {
      await beforeRetry();
    }
    await new Promise<void>((resolve) => setTimeout(resolve, 5000));
  }
  throw {
    error: 'trying_few_times_failed',
    errors,
  };
};

type PackagePartDisplayStatus = 'normal' | 'ok' | 'error';

const PackagePartProgress: Component<{
  parts: Accessor<{
    progress: number;
    total: number;
    status: PackagePartStatus;
  }[]>;
  classList?: { [k: string]: boolean; };
}> = (props) => {
  type Segment = {
    filled: boolean;
    start: Accessor<number>;
    setStart: Setter<number>;
    end: Accessor<number>;
    setEnd: Setter<number>;
    status?: Accessor<PackagePartStatus>,
    setStatus?: Setter<PackagePartStatus>,
    displayStatus: Accessor<PackagePartDisplayStatus>;
    setDisplayStatus: Setter<PackagePartDisplayStatus>;
  };

  const partsCount = createMemo(() => props.parts().length);
  const allSegments = createMemo<Segment[]>((prevAllSegments) => {
    const segmentsCount = partsCount() * 2;
    if(prevAllSegments.length == segmentsCount) return prevAllSegments;
    const allSegments: Segment[] = new Array(segmentsCount);
    for(let i = 0; i < segmentsCount; ++i) {
      const [start, setStart] = createSignal(0);
      const [end, setEnd] = createSignal(0);
      const [status, setStatus] = i % 2 == 0 ? createSignal<PackagePartStatus>('queued') : [];
      const [displayStatus, setDisplayStatus] = createSignal<PackagePartDisplayStatus>('normal');
      allSegments[i] = {
        filled: !(i % 2),
        start, setStart,
        end, setEnd,
        status, setStatus,
        displayStatus, setDisplayStatus,
      };
    }
    return allSegments;
  }, []);
  const visibleSegments = createMemo<Segment[]>(() => {
    const segments = allSegments();
    const parts = props.parts();
    const visibleSegments: Segment[] = [];
    let position = 0;
    for(let partIndex = 0; partIndex < parts.length; ++partIndex) {
      const segmentIndex = partIndex * 2;
      const status = parts[partIndex].status;
      let displayStatus: PackagePartDisplayStatus;
      switch(status) {
      case 'done':
        displayStatus = 'ok';
        break;
      case 'upload_retrying':
        displayStatus = 'error';
        break;
      default:
        displayStatus = 'normal';
        break;
      }
      // progress part
      const progressSize = parts[partIndex].progress;
      const progressSegment = segments[segmentIndex];
      progressSegment.setStart(position);
      position += progressSize;
      progressSegment.setEnd(position);
      progressSegment.setStatus!(status);
      progressSegment.setDisplayStatus(displayStatus);
      if(progressSize > 0) {
        // glue to previous progress part only for 'done' segments
        if(status == 'done' && visibleSegments.length > 0 && visibleSegments[visibleSegments.length - 1].filled && visibleSegments[visibleSegments.length - 1].status!() == status) {
          visibleSegments[visibleSegments.length - 1].setEnd(position);
        } else {
          visibleSegments.push(progressSegment);
        }
      }
      // empty part
      const emptySize = parts[partIndex].total - progressSize;
      const emptySegment = segments[segmentIndex + 1];
      emptySegment.setStart(position);
      position += emptySize;
      emptySegment.setEnd(position);
      if(emptySize > 0) {
        if(visibleSegments.length > 0 && !visibleSegments[visibleSegments.length - 1].filled) {
          visibleSegments[visibleSegments.length - 1].setEnd(position);
        } else {
          visibleSegments.push(emptySegment);
        }
      }
    }
    return visibleSegments;
  });
  const total = createMemo<number>(() => visibleSegments().length ? visibleSegments().at(-1)!.end() : 0);

  const { t } = useContext(LocalizationContext)!;

  // nbsp for working baseline alignment

  return (
    <div classList={{
      part_progress: true,
      ...props.classList ?? {},
    }}>
      <div>
        &nbsp;
        <For each={visibleSegments()}>{({ filled, start, end, status, displayStatus }) =>
          <div classList={{
            filled,
            'status-normal': displayStatus() == 'normal',
            'status-ok': displayStatus() == 'ok',
            'status-error': displayStatus() == 'error',
          }} style={{
            'inset-inline-start': `${(start() / total() * 100).toFixed(3)}%`,
            'inset-inline-end': `${((1 - end() / total()) * 100).toFixed(3)}%`,
          }} title={filled ? t(`packages.upload.part.status.${status!()}`) : undefined} />
        }</For>
      </div>
    </div>
  );
};
