import {
  Component,
  createSignal,
  onMount,
  Show,
  useContext,
} from 'solid-js';
import { Button } from './basic';
import { LocalizationContext } from './localization';
import { AppPage } from './routing';
import { PackageInfo, ProjectInfo } from './api-generated';
import { AuthContext } from '../components';
import config from '../config';
import { keyboardEventKeyCodesToLinuxKeyCodes } from './keymap';

type Status = 'not_started' | 'creating_session' | 'awaiting_machine' | 'connecting' | 'playing' | 'error';

enum Command {
  KeyDown = 0,
  KeyUp = 1,
  KeyPress = 2,
  MouseMove = 3,
  MouseDown = 4,
  MouseUp = 5,
}

enum MouseButton {
  Left = 0,
  Right = 1,
  Middle = 2,
}
const fromMouseEventButton = (button: number): MouseButton | undefined => {
  switch(button) {
  case 0: return MouseButton.Left;
  case 1: return MouseButton.Middle;
  case 2: return MouseButton.Right;
  }
}

export const CloudPlayer: Component<{
  project: ProjectInfo;
  pkg: PackageInfo;
}> = (props) => {
  const { t } = useContext(LocalizationContext)!;
  const { api } = useContext(AuthContext)!;

  let refVideo: HTMLVideoElement;
  let dataChannel: RTCDataChannel | undefined;

  let lastMouseX: number | undefined;
  let lastMouseY: number | undefined;

  class CommandBuffer {
    constructor(maxSize: number = 16) {
      this.buffer = new ArrayBuffer(maxSize);
      this.dataView = new DataView(this.buffer);
    }

    appendKeyDown(key: number) {
      this.dataView.setUint8(this.offset++, Command.KeyDown);
      this.dataView.setUint8(this.offset++, key);
    }
    appendKeyUp(key: number) {
      this.dataView.setUint8(this.offset++, Command.KeyUp);
      this.dataView.setUint8(this.offset++, key);
    }

    appendMouseMove(x: number, y: number) {
      if(x === lastMouseX && y === lastMouseY) return;

      this.dataView.setUint8(this.offset++, Command.MouseMove);
      this.dataView.setUint16(this.offset, x, true);
      this.offset += 2;
      this.dataView.setUint16(this.offset, y, true);
      this.offset += 2;

      lastMouseX = x;
      lastMouseY = y;
    }

    appendMouseDown(button: number) {
      this.dataView.setUint8(this.offset++, Command.MouseDown);
      this.dataView.setUint8(this.offset++, button);
    }

    appendMouseUp(button: number) {
      this.dataView.setUint8(this.offset++, Command.MouseUp);
      this.dataView.setUint8(this.offset++, button);
    }

    send() {
      if(dataChannel && this.offset > 0) {
        dataChannel.send(this.buffer.slice(0, this.offset));
      }
    }

    buffer: ArrayBuffer;
    dataView: DataView;
    offset = 0;
  }

  let videoWidth = 1, videoHeight = 1, videoAspectRatio = 1;

  const mouseCoords = (e: MouseEvent) => {
    const clientWidth = refVideo.clientWidth;
    const clientHeight = refVideo.clientHeight;
    const videoDisplayWidth = Math.min(clientWidth, clientHeight * videoAspectRatio);
    const videoDisplayHeight = Math.min(clientHeight, clientWidth / videoAspectRatio);
    const videoOffsetX = (clientWidth - videoDisplayWidth) * 0.5;
    const videoOffsetY = (clientHeight - videoDisplayHeight) * 0.5;
    const o = {
      x: (e.offsetX - videoOffsetX) * videoWidth / videoDisplayWidth,
      y: (e.offsetY - videoOffsetY) * videoHeight / videoDisplayHeight,
    };
    return (o.x < 0 || o.x >= refVideo.videoWidth || o.y < 0 || o.y >= refVideo.videoHeight) ? null : o;
  };

  const [status, setStatus] = createSignal<Status>('not_started');

  onMount(() => {
    refVideo.addEventListener('resize', (e) => {
      console.log('video resize', refVideo.videoWidth, refVideo.videoHeight);
      if(refVideo.videoWidth != null && refVideo.videoHeight != null) {
        // cache size
        videoWidth = refVideo.videoWidth;
        videoHeight = refVideo.videoHeight;
        videoAspectRatio = videoWidth / videoHeight;
        // set size in style
        refVideo.style.width = `${videoWidth}px`;
        refVideo.style.height = `${videoHeight}px`;
      }
    });

    refVideo.addEventListener('keydown', (e) => {
      e.preventDefault();
      const code = keyboardEventKeyCodesToLinuxKeyCodes.get(e.code);
      if(code == null) return;
      const buffer = new CommandBuffer();
      buffer.appendKeyDown(code);
      buffer.send();
    });
    refVideo.addEventListener('keyup', (e) => {
      e.preventDefault();
      const code = keyboardEventKeyCodesToLinuxKeyCodes.get(e.code);
      if(code == null) return;
      const buffer = new CommandBuffer();
      buffer.appendKeyUp(code);
      buffer.send();
    });
    refVideo.addEventListener('mousemove', (e) => {
      e.preventDefault();
      const coords = mouseCoords(e);
      if(!coords) return;
      const buffer = new CommandBuffer();
      buffer.appendMouseMove(coords.x, coords.y);
      buffer.send();
    });
    refVideo.addEventListener('mousedown', (e) => {
      e.preventDefault();
      refVideo.focus();
      const coords = mouseCoords(e);
      if(!coords) return;
      const button = fromMouseEventButton(e.button);
      if(button == null) return;
      const buffer = new CommandBuffer();
      buffer.appendMouseMove(coords.x, coords.y);
      buffer.appendMouseDown(button);
      buffer.send();
    });
    refVideo.addEventListener('mouseup', (e) => {
      e.preventDefault();
      const coords = mouseCoords(e);
      if(!coords) return;
      const button = fromMouseEventButton(e.button);
      if(button == null) return;
      const buffer = new CommandBuffer();
      buffer.appendMouseMove(coords.x, coords.y);
      buffer.appendMouseUp(button);
      buffer.send();
    });
    refVideo.addEventListener('click', (e) => {
      e.preventDefault();
    });
    refVideo.addEventListener('dblclick', (e) => {
      e.preventDefault();
    });
    refVideo.addEventListener('contextmenu', (e) => {
      e.preventDefault();
    });
  });

  return <>
    <AppPage
      title={t('packages.cloud_play')}
      breadcrumb={t('packages.cloud_play')}
      uiClass="package_cloud_play"
    />
    <video ref={refVideo!} autoplay playsinline muted tabIndex={-1} />
    <div class="footer">
      <div class="buttons">
        <Show when={status() != 'playing'}>
          <div class="note">{t(`packages.cloud_play.status.${status()}`)}</div>
        </Show>
        <div class="filler" />
        <Show when={status() == 'not_started'}>
          <Button onClick={async () => {
            // create session
            setStatus('creating_session');
            const sessionToken = await api.postProjectsPackagesCloudPlay({
              project: props.project.id,
              _package: props.pkg.id,
            });

            // wait for machine
            setStatus('awaiting_machine');
            let remoteOfferStr;
            for(;;) {
              try {
                remoteOfferStr = await api.postCloudPlaySessionInit({
                  requestBody: sessionToken,
                });
                break;
              } catch(e: any) {
                if(e.body == 'timeout') {
                  await new Promise((resolve) => setTimeout(resolve, 1000));
                  continue;
                }
                throw e;
              }
            }

            const stream = new MediaStream();
            const peerConnection = new RTCPeerConnection({
              iceServers: config.webrtcIceServers,
              bundlePolicy: 'max-bundle',
            });
            peerConnection.addEventListener('datachannel', (e) => {
              dataChannel = e.channel;
              console.log('dataChannel', dataChannel.label);
            });
            peerConnection.addEventListener('track', (e) => {
              const track = e.track;
              switch(track.kind) {
              case 'video':
                if(stream.getVideoTracks().length <= 0) {
                  console.log('adding video track');
                  stream.addTrack(track);
                }
                break;
              case 'audio':
                if(stream.getAudioTracks().length <= 0) {
                  console.log('adding audio track');
                  stream.addTrack(track);
                }
                break;
              }
              if(!refVideo.srcObject && stream.getVideoTracks().length > 0 && stream.getAudioTracks().length > 0) {
                console.log('starting playing');
                refVideo.srcObject = stream;
                refVideo.play();
              }
            });
            peerConnection.addEventListener('connectionstatechange', (e) => {
              console.log('connectionstatechange', peerConnection.connectionState);
              if(peerConnection.connectionState == 'connected') {
                setStatus('playing');
              }
            });
            peerConnection.addEventListener('iceconnectionstatechange', (e) => {
              console.log('iceconnectionstatechange', peerConnection.iceConnectionState);
            });
            peerConnection.addEventListener('icegatheringstatechange', (e) => {
              console.log('icegatheringstatechange', peerConnection.iceGatheringState);
            });
            peerConnection.addEventListener('signalingstatechange', (e) => {
              console.log('signalingstatechange', peerConnection.signalingState);
            });
            peerConnection.addEventListener('negotiationneeded', async (e) => {
              console.log('negotiationneeded');
            });
            peerConnection.addEventListener('icecandidate', async (e) => {
              if(e.candidate) {
                console.log('candidate', e.candidate);
              } else {
                console.log('no more candidates');
                console.log('localDescription', peerConnection.localDescription!.type);
                console.log(peerConnection.localDescription!.sdp);
                setStatus('connecting');
                await api.postCloudPlaySessionConnect({
                  requestBody: {
                    token: sessionToken,
                    sdp: JSON.stringify(peerConnection.localDescription!.toJSON()),
                  },
                });
              }
            });

            const remoteOffer = JSON.parse(remoteOfferStr);
            console.log('remoteOffer', remoteOffer.type);
            console.log(remoteOffer.sdp);
            await peerConnection.setRemoteDescription(remoteOffer);
            await peerConnection.setLocalDescription();
          }}>{t('packages.cloud_play.start')}</Button>
        </Show>
        <Show when={status() == 'playing'}>
          <Button onClick={() => {
            refVideo.requestFullscreen({
              navigationUI: 'hide',
            });
            refVideo.focus();
          }}>{t('packages.cloud_play.fullscreen')}</Button>
        </Show>
      </div>
    </div>
  </>;
};
