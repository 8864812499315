import {
  Set as ISet,
} from 'immutable';
import { Duration } from 'luxon';
import {
  Accessor,
  Component,
  For,
  Show,
  createMemo,
  createResource,
  useContext,
} from 'solid-js';
import {
  AppPage,
  AuthContext,
  Button,
  CloudPlayer,
  DeployProviderIcon,
  Dropdown,
  EntryContextProvider,
  FaIcon,
  FormatDate,
  Link,
  LocalizationContext,
  MarkdownView,
  PackageActionButton,
  PageNotFound,
  PrettyDataSize,
  ProjectActionsButtons,
  ProjectUserRole,
  ResourceFallback,
  Routes,
  Tags,
  UploadBox,
  WebPlayer,
  callDialog,
  compareProjectUserRoles,
  projectUserRoles,
  readEntriesFromArchive,
  useRoutingNavigate,
} from '../../components';
import {
  BranchInfo,
  DeploySiteInfo,
  UserInfo,
  VersionInfo,
} from '../../components/api-generated';
import config from '../../config';
import { AddDeploySiteDialog } from '../../dialogs/add_deploy_site';
import {
  ConfirmDialog,
  OkCopyDialog,
  PromptDialog,
  PromptMarkdownDialog,
  showErrorScope,
} from '../../dialogs/basic';
import { DeployToSiteDialog } from '../../dialogs/deploy_to_site';
import { EditPackageTagsDialog } from '../../dialogs/edit_package_tags';
import { NewVersionDialog } from '../../dialogs/new_version';
import { PublishExistingVersionDialog } from '../../dialogs/publish_existing_version';
import { PublishVersionDialog } from '../../dialogs/publish_version';
import {
  linkInvite,
  linkProject,
  linkProjectBranch,
  linkProjectBranchVersion,
  linkProjectBranchVersionPackage,
  linkProjectManage,
  linkProjects,
  linkStoreProject,
} from './links';

export const RouteProjects: Component = () => {
  const { api } = useContext(AuthContext)!;
  const { t } = useContext(LocalizationContext)!;
  const navigate = useRoutingNavigate();

  const [projects, { refetch: refetchProjects }] = createResource(async () => {
    return await api.getUsersMeProjects()
  });
  const projectsById = createMemo(() => Object.fromEntries(projects()?.map((project) => [project.id, project]) ?? []));

  const onCreateProject = async () => {
    const title = await callDialog(PromptDialog, {
      header: t('projects.new_dialog.header'),
      label: t('projects.new_dialog.label'),
      ok: t('projects.new_dialog.ok'),
    });
    if(title == null) return;
    await showErrorScope(async () => {
      const projectId = await api.postProjects({
        requestBody: {
          title,
        },
      });
      refetchProjects();
      navigate(linkProject(projectId));
    });
  };

  return <>
    <AppPage
      title={t('route.projects')}
      breadcrumb={<Link href={linkProjects}>{t('route.projects')}</Link>}
      uiClass="projects"
    />
    <Routes routes={[
      {
        path: '',
        component: () => <>
          <h1>{t('route.projects')}</h1>
          <div class="list projects">
            <For each={projects()} fallback={
              <div>
                <ResourceFallback resource={projects} empty={<div class="empty">{t('projects.no_projects')}</div>} />
              </div>
            }>{(project) =>
              <Link href={linkProject(project.id)}>
                <div class="title">{project.title}</div>
              </Link>
            }</For>
          </div>
          <div class="footer">
            <div class="buttons">
              <Button onClick={onCreateProject}>{t('projects.new')}</Button>
            </div>
          </div>
        </>,
      },
      {
        component: (props) => {
          const project = createMemo(() => projectsById()[props.route]!);

          const [projectQuotas] = createResource(project, async (project) => {
            return await api.getProjectsQuotas({
              project: project.id,
            });
          });

          const [branches, { refetch: refetchBranches }] = createResource(project, async (project) => {
            return await api.getProjectsBranches({
              project: project.id,
            });
          });

          const branchesById = createMemo(() => Object.fromEntries(branches()?.map((branch) =>
            [branch.id, branch]
          ) ?? []));

          const [versions, { refetch: refetchVersions }] = createResource(project, async (project) => {
            return await api.getProjectsVersions({
              project: project.id,
            });
          });

          const versionsById = createMemo(() => Object.fromEntries(versions()?.map((version) =>
            [version.id, version]
          ) ?? []));

          const onCreateBranch = async () => {
            const title = await callDialog(PromptDialog, {
              header: t('branches.new_dialog.header'),
              label: t('branches.new_dialog.label'),
              ok: t('branches.new_dialog.ok'),
            });
            if(title == null) return null;
            await showErrorScope(async () => {
              const branchId = await api.postProjectsBranches({
                project: project().id,
                requestBody: {
                  title,
                },
              });
              navigate(linkProjectBranch(project().id, branchId));
            });
            refetchBranches();
          };

          const onProjectAction = async (key: string) => {
            switch(key) {
            case 'rename': {
              const title = await callDialog(PromptDialog, {
                header: t('projects.rename_dialog.header'),
                label: t('projects.rename_dialog.label'),
                value: project().title,
                ok: t('projects.rename_dialog.ok'),
                focus: 'cancel',
              });
              if(title !== undefined) {
                await showErrorScope(async () => {
                  await api.patchProjects({
                    project: project().id,
                    requestBody: {
                      title,
                    },
                  });
                });
                refetchProjects();
              }
              break;
            }
            case 'edit_description': {
              const description = await callDialog(PromptMarkdownDialog, {
                header: t('projects.edit_description_dialog.header'),
                label: t('projects.edit_description_dialog.label'),
                value: project().description,
                ok: t('projects.edit_description_dialog.ok'),
                focus: 'cancel',
              });
              if(description !== undefined) {
                await showErrorScope(async () => {
                  await api.patchProjects({
                    project: project().id,
                    requestBody: {
                      description,
                    },
                  });
                });
                refetchProjects();
              }
              break;
            }
            case 'leave': {
              if(!await callDialog(ConfirmDialog, {
                header: t('projects.leave_dialog.header'),
                message: t('projects.leave_dialog.message')(project().title),
                ok: t('projects.leave_dialog.ok'),
                focus: 'cancel',
                danger: true,
              })) return;
              await showErrorScope(async () => {
                await api.postProjectsUsersLeave({
                  project: project().id,
                });
              });
              refetchProjects();
              navigate(linkProjects);
              break;
            }
            case 'delete': {
              if(!await callDialog(ConfirmDialog, {
                header: t('projects.delete_dialog.header'),
                message: t('projects.delete_dialog.message')(project().title),
                ok: t('projects.delete_dialog.ok'),
                focus: 'cancel',
                danger: true,
              })) return;
              await showErrorScope(async () => {
                await api.deleteProjects({
                  project: project().id,
                });
              });
              refetchProjects();
              navigate(linkProjects);
              break;
            }
            }
          };

          const VersionsComponent: Component<{
            branch?: Accessor<BranchInfo>;
          }> = ({ branch }) => <>
            <AppPage
              title={t('projects.versions')}
              breadcrumb={t('projects.versions')}
              uiClass="versions"
            />
            <Routes routes={[
              {
                component: (props) => {
                  const version = createMemo(() => versionsById()[props.route]!);

                  const [versionPackages, { refetch: refetchVersionPackages }] = createResource(version, async (version) => {
                    return await api.getProjectsVersionsPackages({
                      project: project().id,
                      version: version.id,
                    });
                  });

                  return <>
                    <AppPage
                      title={t('versions.header')(project().title, version()?.title ?? '')}
                      breadcrumb={version() &&
                        <Link href={linkProjectBranchVersion({
                          projectId: project().id,
                          branchId: branch?.()?.id,
                          versionId: version().id
                        })}>{version().title}</Link>
                      }
                      uiClass="version"
                    />
                    <Show when={version()} fallback={<ResourceFallback resource={versions} page />}>
                      <Routes routes={[
                        {
                          path: '',
                          component: () => {
                            const [versionBranches, { refetch: refetchVersionBranches }] = createResource(version, async (version) => {
                              return await api.getProjectsVersionsBranches({
                                project: project().id,
                                version: version.id,
                              });
                            });

                            const onPublish = async () => {
                              const branchId = await callDialog(PublishVersionDialog, {
                                project: project(),
                                version: version(),
                              });
                              if(!branchId) return;
                              await showErrorScope(async () => {
                                await api.postProjectsBranchesVersions({
                                  project: project().id,
                                  branch: branchId,
                                  version: version().id,
                                });
                                refetchBranches();
                                refetchVersionBranches();
                              });
                            };

                            const onDeployToSite = async () => {
                              if(!await callDialog(DeployToSiteDialog, {
                                project: project(),
                                version: version(),
                              })) return;
                            };

                            const onVersionAction = async (key: string) => {
                              switch(key) {
                              case 'rename': {
                                const newVersionTitle = await callDialog(PromptDialog, {
                                  header: t('versions.rename_dialog.header'),
                                  label: t('versions.rename_dialog.label'),
                                  value: version().title,
                                  ok: t('versions.rename_dialog.ok'),
                                });
                                if(!newVersionTitle) return;
                                await showErrorScope(async () => {
                                  await api.patchProjectsVersions({
                                    project: project().id,
                                    version: version().id,
                                    requestBody: {
                                      title: newVersionTitle,
                                    },
                                  });
                                });
                                refetchVersions();
                                refetchBranches();
                                break;
                              }
                              case 'delete': {
                                if(!await callDialog(ConfirmDialog, {
                                  header: t('versions.delete_dialog.header'),
                                  message: t('versions.delete_dialog.message')(version().title),
                                  ok: t('versions.delete_dialog.ok'),
                                  focus: 'cancel',
                                  danger: true,
                                })) return;
                                await showErrorScope(async () => {
                                  await api.deleteProjectsVersions({
                                    project: project().id,
                                    version: version().id,
                                  });
                                });
                                refetchVersions();
                                refetchBranches();
                                navigate(branch ? linkProjectBranch(project().id, branch().id) : linkProject(project().id));
                                break;
                              }
                              }
                            };

                            return <>
                              <h1>{t('versions.header')(project().title, version().title)}<div class="hint">{t('versions.header.hint')}</div></h1>
                              <h2>{t('versions.packages')}</h2>
                              <div class="list packages">
                                <For each={versionPackages()} fallback={
                                  <div>
                                    <ResourceFallback resource={versionPackages} />
                                  </div>
                                }>{(pkg) =>
                                  <Link href={linkProjectBranchVersionPackage({
                                    projectId: project().id,
                                    branchId: branch?.()?.id,
                                    versionId: version().id,
                                    packageId: pkg.id,
                                  })}>
                                    <div class="title">{pkg.title}</div>
                                    <PrettyDataSize size={pkg.size} />
                                    <div class="published"><FormatDate date={new Date(pkg.created)} /></div>
                                    <Tags tags={ISet(pkg.tags)} />
                                    <div class="buttons group">
                                      <PackageActionButton project={project()} branch={branch?.()} version={version()} pkg={pkg} />
                                    </div>
                                  </Link>
                                }</For>
                              </div>
                              <h2>{t('versions.branches')}</h2>
                              <Show when={project().userPossibleActions.indexOf('publishVersion') >= 0}>
                                <div class="buttons">
                                  <Button onClick={onPublish}>{t('versions.branches.publish')}</Button>
                                </div>
                              </Show>
                              <div class="list branches">
                                <For each={versionBranches()} fallback={
                                  <div>
                                    <ResourceFallback resource={versionBranches} empty={<div class="empty">{t('versions.branches.empty')}</div>} />
                                  </div>
                                }>{(versionBranch) =>
                                  <Link href={linkProjectBranch(project().id, versionBranch.branchId)}>
                                    <div class="title">{versionBranch.branchTitle}</div>
                                    <div class="hint">{t(versionBranch.isCurrent ? 'versions.current' : 'versions.previous')}</div>
                                    <div class="published"><FormatDate date={new Date(versionBranch.published)} /></div>
                                  </Link>
                                }</For>
                              </div>
                              <Show when={project().userPossibleActions.indexOf('deployVersionToSite') >= 0}>
                                <h2>{t('versions.deploy_to_site.header')}</h2>
                                <div class="buttons">
                                  <Button onClick={onDeployToSite}>{t('versions.deploy_to_site')}</Button>
                                </div>
                              </Show>
                              <div class="footer">
                                <ProjectActionsButtons project={project} title={t('versions.manage')} items={[
                                  {
                                    key: 'rename',
                                    title: t('versions.rename'),
                                    action: 'patchVersion',
                                  },
                                  {
                                    key: 'delete',
                                    title: t('versions.delete'),
                                    danger: true,
                                    action: 'deleteVersion',
                                  },
                                ]} onSelect={onVersionAction} />
                              </div>
                            </>;
                          },
                        },
                        {
                          path: 'packages',
                          component: () => <PackagesComponent branch={branch} version={version} refetchVersionPackages={refetchVersionPackages} />,
                        },
                      ]} />
                    </Show>
                  </>;
                },
              },
            ]} />
          </>;

          const PackagesComponent: Component<{
            branch?: Accessor<BranchInfo>;
            version?: Accessor<VersionInfo>;
            refetchVersionPackages?: () => void;
          }> = ({ branch, version, refetchVersionPackages }) => <>
            <AppPage
              title={t('projects.packages')}
              breadcrumb={t('projects.packages')}
              uiClass="packages"
            />
            <Routes routes={[
              {
                path: '',
                component: () => {
                  return <>
                  </>;
                },
              },
              {
                component: (props) => {
                  const [pkg, { refetch: refetchPackage }] = createResource(async () => {
                    try {
                      return await api.getProjectsPackages1({
                        project: project().id,
                        _package: props.route,
                      });
                    } catch(e) {
                    }
                  });

                  return <>
                    <AppPage
                      title={t('packages.header')(project().title, pkg()?.title ?? '')}
                      breadcrumb={pkg() &&
                        <Link href={linkProjectBranchVersionPackage({
                          projectId: project().id,
                          branchId: branch?.()?.id,
                          versionId: version?.()?.id,
                          packageId: pkg()!.id,
                        })}>{pkg()?.title ?? ''}</Link>
                      }
                      uiClass="package"
                    />
                    <Show when={pkg()} fallback={<ResourceFallback resource={pkg} page />}>
                      <Routes routes={[
                        {
                          path: '',
                          component: () => {
                            const [rootEntry] = createResource(pkg, async (pkg) => {
                              const url = new URL(await api.getProjectsPackagesDownload({
                                project: project().id,
                                _package: pkg.id,
                              }), config.storageBaseUrl);
                              url.searchParams.set('filter', 'index');
                              return await readEntriesFromArchive(url.toString());
                            });

                            const onPackageAction = async (key: string) => {
                              switch(key) {
                              case 'rename': {
                                const newPackageTitle = await callDialog(PromptDialog, {
                                  header: t('packages.rename_dialog.header'),
                                  label: t('packages.rename_dialog.label'),
                                  value: pkg()!.title,
                                  ok: t('packages.rename_dialog.ok'),
                                });
                                if(!newPackageTitle) return;
                                await showErrorScope(async () => {
                                  await api.patchProjectsPackages({
                                    project: project().id,
                                    _package: pkg()!.id,
                                    requestBody: {
                                      title: newPackageTitle,
                                    },
                                  });
                                });
                                refetchPackage();
                                refetchVersionPackages?.();
                                break;
                              }
                              case 'edit_tags': {
                                const newPackageTags = await callDialog(EditPackageTagsDialog, {
                                  pkg: pkg()!,
                                });
                                if(newPackageTags == undefined) return;
                                await showErrorScope(async () => {
                                  await api.patchProjectsPackages({
                                    project: project().id,
                                    _package: pkg()!.id,
                                    requestBody: {
                                      tags: newPackageTags.toArray(),
                                    },
                                  });
                                });
                                refetchPackage();
                                refetchVersionPackages?.();
                                break;
                              }
                              case 'delete': {
                                if(!await callDialog(ConfirmDialog, {
                                  header: t('packages.delete_dialog.header'),
                                  message: t('packages.delete_dialog.message')(pkg()!.title),
                                  ok: t('packages.delete_dialog.ok'),
                                  focus: 'cancel',
                                  danger: true,
                                })) return;
                                await showErrorScope(async () => {
                                  await api.deleteProjectsPackages({
                                    project: project().id,
                                    _package: pkg()!.id,
                                  });
                                });
                                refetchVersionPackages?.();
                                navigate(version ? linkProjectBranchVersion({
                                  projectId: project().id,
                                  branchId: branch?.()?.id,
                                  versionId: version().id,
                                }) : linkProject(project().id));
                                break;
                              }
                              }
                            };

                            return <>
                              <h1>{t('packages.header')(project().title, pkg()!.title)}<div class="hint">{t('packages.header.hint')}</div></h1>
                              <div class="buttons group">
                                <PackageActionButton project={project()} branch={branch?.()} version={version?.()} pkg={pkg()!} />
                              </div>
                              <Tags tags={ISet(pkg()!.tags)} />
                              <Show when={rootEntry()} fallback={
                                <div class="message">
                                  <ResourceFallback resource={rootEntry} />
                                </div>
                              }>
                                <EntryContextProvider>
                                  <UploadBox root={rootEntry()!} />
                                </EntryContextProvider>
                                <div class="size">{
                                  t('packages.total_size')(
                                    <span class="inline_size"><PrettyDataSize size={rootEntry()!.totalSize()} /></span>
                                  )
                                }</div>
                              </Show>
                              <div class="footer">
                                <ProjectActionsButtons project={project} title={t('packages.manage')} items={[
                                  {
                                    key: 'rename',
                                    title: t('packages.rename'),
                                    action: 'patchPackage',
                                  },
                                  {
                                    key: 'edit_tags',
                                    title: t('packages.edit_tags'),
                                    action: 'patchPackage',
                                  },
                                  {
                                    key: 'delete',
                                    title: t('packages.delete'),
                                    danger: true,
                                    action: 'deletePackage',
                                  },
                                ]} onSelect={onPackageAction} />
                              </div>
                            </>;
                          },
                        },
                        {
                          path: 'play',
                          component: () => {
                            const [spaceUrlResource] = createResource(async () => {
                              return await api.getProjectsPackagesSpace({
                                project: project().id,
                                _package: pkg()!.id,
                              });
                            });
                            return <WebPlayer spaceUrlResource={spaceUrlResource} />;
                          },
                        },
                        {
                          path: 'cloud-play',
                          component: () => <CloudPlayer project={project()} pkg={pkg()!} />,
                        },
                      ]} />
                    </Show>
                  </>;
                },
              },
            ]} />
          </>;

          return <>
            <AppPage
              title={project()?.title ?? ''}
              breadcrumb={project() && <Link href={linkProject(project().id)}>{project().title}</Link>}
              uiClass="project"
            />
            <Show when={project()} fallback={<ResourceFallback resource={projects} page />}>
              <Routes routes={[
                {
                  path: '',
                  component: (props) =>
                    <>
                      <h1>
                        {project().title}
                        <Show when={project().userPossibleActions.indexOf('patchProject') >= 0}>
                          {' '}<Button light onClick={() => onProjectAction('rename')}>{t('projects.rename')}</Button>
                        </Show>
                        <div class="hint">{t('projects.project.hint')}</div>
                      </h1>
                      <Show when={project().isPublic}>
                        <p>
                          <a href={linkStoreProject(project().id)} target="_blank">
                            <FaIcon solid weak inline store />
                            {t('projects.store_page')}
                          </a>
                        </p>
                      </Show>
                      <h2>
                        {t('projects.description')}
                        <Show when={project().userPossibleActions.indexOf('patchProject') >= 0}>
                          {' '}<Button light onClick={() => onProjectAction('edit_description')}>{t('projects.edit_description')}</Button>
                        </Show>
                      </h2>
                      <MarkdownView text={() => project().description} level={3} ugc linkInNewTab />
                      <h2>{t('projects.branches')}</h2>
                      <div class="buttons">
                        <Show when={project().userPossibleActions.indexOf('createBranch') >= 0}>
                          <Button onClick={onCreateBranch}>{t('branches.new')}</Button>
                        </Show>
                      </div>
                      <div class="list branches">
                        <For each={branches()} fallback={
                          <div>
                            <ResourceFallback resource={branches} empty={<div class="empty">{t('branches.no_branches')}</div>} />
                          </div>
                        }>{(branch) =>
                          <Link href={linkProjectBranch(project().id, branch.id)}>
                            <div class="title">{branch.title}</div>
                            <Show when={branch.currentVersion}>
                              <div class="current_version">{branch.currentVersion!.version.title}</div>
                              <div class="current_version_published"><FormatDate date={new Date(branch.currentVersion!.published)} /></div>
                            </Show>
                          </Link>
                        }</For>
                      </div>
                      <div class="footer">
                        <div class="buttons align_end">
                          <Link href={linkProjectManage(project().id)}>{t('projects.management')}</Link>
                        </div>
                      </div>
                    </>,
                },
                {
                  path: 'branches',
                  component: () =>
                    <>
                      <AppPage
                        title={t('projects.branches')}
                        breadcrumb={t('projects.branches')}
                        uiClass="branches"
                      />
                      <Routes routes={[
                        {
                          component: (props) => {
                            const branch = createMemo(() => branchesById()[props.route]);

                            const [branchVersions] = createResource(branch, async (branch) => {
                              return await api.getProjectsBranchesVersions({
                                project: project().id,
                                branch: branch.id,
                              });
                            });

                            const putVersion = async (versionId: string) => {
                              await showErrorScope(async () => {
                                await api.postProjectsBranchesVersions({
                                  project: project().id,
                                  branch: branch().id,
                                  version: versionId,
                                });
                                refetchBranches();
                              });
                            };

                            const onPublishNewVersion = async () => {
                              const baseVersion = branch().currentVersion?.version;
                              const versionId = await callDialog(NewVersionDialog, {
                                project: project(),
                                projectQuotas: projectQuotas(),
                                branch: branch(),
                                baseVersion,
                                initialVersionTitle: calculateNextVersionTitle(baseVersion?.title) ?? '1.0.0',
                              });
                              if(!versionId) return;
                              refetchVersions();
                              await putVersion(versionId);
                            };

                            const onPublishExistingVersion = async () => {
                              const versionId = await callDialog(PublishExistingVersionDialog, {
                                project: project(),
                                currentVersion: branch().currentVersion?.version,
                              });
                              if(!versionId) return;
                              await putVersion(versionId);
                            };

                            const onBranchAction = async (key: string) => {
                              switch(key) {
                              case 'rename': {
                                const newBranchTitle = await callDialog(PromptDialog, {
                                  header: t('branches.rename_dialog.header'),
                                  label: t('branches.rename_dialog.label'),
                                  value: branch().title,
                                  ok: t('branches.rename_dialog.ok'),
                                });
                                if(!newBranchTitle) return;
                                await showErrorScope(async () => {
                                  await api.patchProjectsBranches({
                                    project: project().id,
                                    branch: branch().id,
                                    requestBody: {
                                      title: newBranchTitle,
                                    },
                                  });
                                });
                                refetchBranches();
                                break;
                              }
                              case 'delete': {
                                if(!await callDialog(ConfirmDialog, {
                                  header: t('branches.delete_dialog.header'),
                                  message: t('branches.delete_dialog.message')(branch().title),
                                  ok: t('branches.delete_dialog.ok'),
                                  focus: 'cancel',
                                  danger: true,
                                })) return;
                                await showErrorScope(async () => {
                                  await api.deleteProjectsBranches({
                                    project: project().id,
                                    branch: branch().id,
                                  });
                                });
                                refetchBranches();
                                navigate(linkProject(project().id));
                                break;
                              }
                              }
                            };

                            return <>
                              <AppPage
                                title={t('branches.header')(project().title, branch()?.title ?? '')}
                                breadcrumb={branch() && <Link href={linkProjectBranch(project().id, branch().id)}>{branch().title}</Link>}
                                uiClass="branch"
                              />
                              <Show when={branch()} fallback={<ResourceFallback resource={branches} page />}>
                                <Routes routes={[
                                  {
                                    path: '',
                                    component: () => <>
                                      <h1>{t('branches.header')(project().title, branch().title)}<div class="hint">{t('branches.header.hint')}</div></h1>
                                      <h2>{t('branches.current_version')}</h2>
                                      <div class="current_version">
                                        <Show when={branch().currentVersion} fallback={t('branches.no_current_version')}>
                                          <Link class="title" href={linkProjectBranchVersion({
                                            projectId: project().id,
                                            branchId: branch().id,
                                            versionId: branch().currentVersion!.version.id
                                          })}>{branch().currentVersion!.version.title}</Link>
                                        </Show>
                                      </div>
                                      <Show when={project().userPossibleActions.indexOf('publishVersion') >= 0}>
                                        <h3>{t('branches.publish_version')}</h3>
                                        <div class="buttons">
                                          <Button onClick={onPublishNewVersion}>{t('branches.publish_version.new')}</Button>
                                          <Button onClick={onPublishExistingVersion}>{t('branches.publish_version.existing')}</Button>
                                        </div>
                                      </Show>
                                      <h2>{t('branches.version_history')}</h2>
                                      <div class="list branch_versions">
                                        <For each={branchVersions()} fallback={
                                          <div>
                                            <ResourceFallback resource={branchVersions} empty={<div class="message">{t('branches.version_history.empty')}</div>} />
                                          </div>
                                        }>{(branchVersion) =>
                                          <>
                                            <Link href={linkProjectBranchVersion({
                                              projectId: project().id,
                                              branchId: branch().id,
                                              versionId: branchVersion.version.id
                                            })}>
                                              <div class="title">{branchVersion.version.title}</div>
                                              <div class="hint">{t(branchVersion.isCurrent ? 'versions.current' : 'versions.previous')}</div>
                                              <div class="published"><FormatDate date={new Date(branchVersion.published)} /></div>
                                            </Link>
                                          </>
                                        }</For>
                                      </div>
                                      <Show when={(branchVersions()?.length ?? 0) > 0}>
                                        <div class="note"><FaIcon solid warning inline />{t('branches.version_history.note_removal')({
                                          quotaCount: projectQuotas()?.branchVersionsCount,
                                          quotaNonCurrentTime: Duration.fromObject({
                                            seconds: projectQuotas()?.branchVersionsNonCurrentTime,
                                          }, {
                                            locale: t('locale'),
                                          }).shiftTo('days').toHuman(),
                                          quotaTime: Duration.fromObject({
                                            seconds: projectQuotas()?.branchVersionsTime,
                                          }, {
                                            locale: t('locale'),
                                          }).shiftTo('days').toHuman()
                                        })}</div>
                                      </Show>
                                      <div class="footer">
                                        <ProjectActionsButtons project={project} title={t('branches.manage')} items={[
                                          {
                                            key: 'rename',
                                            title: t('branches.rename'),
                                            action: 'patchBranch',
                                          },
                                          {
                                            key: 'delete',
                                            title: t('branches.delete'),
                                            danger: true,
                                            action: 'deleteBranch',
                                          },
                                        ]} onSelect={onBranchAction} />
                                      </div>
                                    </>,
                                  },
                                  {
                                    path: 'versions',
                                    component: () => <VersionsComponent branch={branch} />,
                                  },
                                ]} />
                              </Show>
                            </>;
                          },
                        },
                      ]} />
                    </>,
                },
                {
                  path: 'versions',
                  component: () => <VersionsComponent />,
                },
                {
                  path: 'packages',
                  component: () => <PackagesComponent />,
                },
                {
                  path: 'manage',
                  component: () => {
                    const [users, { refetch: refetchUsers }] = createResource(async () => {
                      const users = await api.getProjectsUsers({
                        project: project().id,
                      });
                      const collator = Intl.Collator(t('locale'));
                      return users.toSorted((a, b) => {
                        if(a.isOwner > b.isOwner) return -1;
                        if(a.isOwner < b.isOwner) return 1;
                        const roleOrder = -compareProjectUserRoles(a.role, b.role);
                        if(roleOrder != 0) return roleOrder;
                        return collator.compare(a.user.title, b.user.title);
                      });
                    });

                    const onSetProjectUser = (user: UserInfo, role?: ProjectUserRole) => showErrorScope(async () => {
                      if(role == undefined) {
                        if(!await callDialog(ConfirmDialog, {
                          header: t('projects.management.users.exile.dialog.header'),
                          message: t('projects.management.users.exile.dialog.message')(project().title, user.title),
                          ok: t('projects.management.users.exile.dialog.ok'),
                          danger: true,
                        })) return;
                      }
                      await api.putProjectsUsers({
                        project: project().id,
                        requestBody: {
                          user: user.id,
                          role: role,
                        },
                      });
                      refetchUsers();
                    });

                    const onOneTimeInvite = () => showErrorScope(async () => {
                      const {
                        token,
                      } = await api.postProjectsInvites({
                        project: project().id,
                        requestBody: {
                          approved: true,
                          expires: new Date(Date.now() + 24 * 3600 * 1000).toISOString(),
                          seats: 1,
                        },
                      });
                      const inviteUrl = new URL(linkInvite(token), window.location.origin).toString();
                      await callDialog(OkCopyDialog, {
                        header: t('projects.management.invites.one_time_invite.header'),
                        message: t('projects.management.invites.one_time_invite.message'),
                        text: inviteUrl,
                        note: t('projects.management.invites.one_time_invite.note'),
                      });
                    });

                    const [deploySites, { refetch: refetchDeploySites }] = createResource(async () => {
                      return await api.getProjectsDeploySites({
                        project: project().id,
                      });
                    });

                    const onAddDeploySite = async () => {
                      const deploySiteId = await callDialog(AddDeploySiteDialog, {
                        project: project(),
                      });
                      if(deploySiteId) {
                        refetchDeploySites();
                      }
                    };

                    const onToggleProjectIsPublic = async () => {
                      const enable = !project().isPublic;

                      if(!await callDialog(ConfirmDialog, {
                        header: t(`projects.management.public_access.${enable ? 'enable' : 'disable'}_dialog.header`),
                        message: t(`projects.management.public_access.${enable ? 'enable' : 'disable'}_dialog.message`)(project().title),
                        focus: 'cancel',
                        ok: t(`projects.management.public_access.${enable ? 'enable' : 'disable'}_dialog.ok`),
                        danger: true,
                      })) return;

                      await showErrorScope(async () => {
                        await api.patchProjects({
                          project: project().id,
                          requestBody: {
                            isPublic: enable,
                          },
                        });
                      });

                      refetchProjects();
                    };

                    const onDeleteDeploySite = async (deploySite: DeploySiteInfo) => {
                      if(!await callDialog(ConfirmDialog, {
                        header: t('projects.management.deploy_sites.delete_dialog.header'),
                        message: t('projects.management.deploy_sites.delete_dialog.message')(deploySite),
                        ok: t('projects.management.deploy_sites.delete_dialog.ok'),
                        danger: true,
                        focus: 'cancel',
                      })) return;

                      await showErrorScope(async () => {
                        await api.deleteProjectsDeploySites({
                          project: project().id,
                          deploySite: deploySite.id,
                        });
                      });

                      refetchDeploySites();
                    };

                    const onBranchTogglePublicAccess = async (branchId: string, e: Event & {
                      target: HTMLInputElement;
                    }) => {
                      e.target.disabled = true;
                      await showErrorScope(async () => {
                        await api.patchProjectsBranches({
                          project: project().id,
                          branch: branchId,
                          requestBody: {
                            isPublic: e.target.checked,
                          },
                        });
                      });
                      e.target.disabled = false;

                      refetchBranches();
                    }

                    return <>
                      <AppPage
                        title={t('projects.management.title')(project().title)}
                        breadcrumb={<Link href={linkProjectManage(project().id)}>{t('projects.management.breadcrumb')}</Link>}
                        uiClass="project_management"
                      />
                      <h1>{project().title}<div class="hint">{t('projects.management.hint')}</div></h1>
                      <h2>{t('projects.management.users')}</h2>
                      <div class="list project_users">
                        <For each={users()} fallback={
                          <div>
                            <ResourceFallback resource={users} empty={<div class="empty">{t('projects.management.users.empty')}</div>} />
                          </div>
                        }>{(user) =>
                          <div>
                            <FaIcon solid fa-2xl
                              user={!user.isOwner && user.role == 'tester'}
                              user-gear={!user.isOwner && user.role == 'developer'}
                              user-tie={!user.isOwner && user.role == 'admin'}
                              crown={user.isOwner}
                            />
                            <div class="title">{user.user.title}</div>
                            <Show when={!user.isOwner && project().userPossibleActions.indexOf('setUser') >= 0} fallback={
                              <div class="role">{t(`projects.management.users.role.${user.isOwner ? 'owner' : user.role}`)}</div>
                            }>
                              <Dropdown<ProjectUserRole | undefined, {
                                key: ProjectUserRole | undefined;
                                title: any;
                                danger?: boolean;
                              }> classList={{
                                role: true,
                              }} title={t(`projects.management.users.role.${user.role}`)} items={[
                                ...projectUserRoles.toReversed().map((role) => ({
                                  key: role,
                                  title: <><FaIcon regular circle={user.role != role} circle-dot={user.role == role} /> {t(`projects.management.users.role.${role}`)}</>,
                                })),
                                {
                                  key: undefined,
                                  title: t('projects.management.users.exile'),
                                  danger: true,
                                },
                              ]} onSelect={(key) => onSetProjectUser(user.user, key)} />
                            </Show>
                          </div>
                        }</For>
                      </div>
                      <Show when={project().userPossibleActions.indexOf('createInvite') >= 0}>
                        <div class="buttons">
                          <Button onClick={onOneTimeInvite}>{t('projects.management.invites.one_time_invite')}</Button>
                        </div>
                      </Show>
                      <h2>{t('projects.management.deploy_sites')}</h2>
                      <div class="list deploy_sites">
                        <For each={deploySites()} fallback={
                          <div>
                            <ResourceFallback resource={deploySites} empty={<div class="empty">{t('projects.management.deploy_sites.empty')}</div>} />
                          </div>
                        }>{(deploySite) =>
                          <div>
                            <DeployProviderIcon provider={deploySite.provider} big />
                            <div class="site">{deploySite.site}</div>
                            <Show when={project().userPossibleActions.indexOf('deleteDeploySite') >= 0}>
                              <div class="buttons">
                                <Button onClick={() => onDeleteDeploySite(deploySite)} danger>{t('projects.management.deploy_sites.delete')}</Button>
                              </div>
                            </Show>
                            <div class="provider">{t(`deploy_credential_provider.${deploySite.provider}`)}</div>
                          </div>
                        }</For>
                        <Show when={project().userPossibleActions.indexOf('createDeploySite') >= 0}>
                          <div>
                            <div class="footer">
                              <div class="buttons">
                                <Button onClick={onAddDeploySite}>{t('projects.management.deploy_sites.add')}</Button>
                              </div>
                            </div>
                          </div>
                        </Show>
                      </div>
                      <h2>{t('projects.management.public_access')}</h2>
                      <div class="list public_access">
                        <div>
                          <FaIcon solid fa-2xl {...{
                            lock: !project().isPublic,
                            bullhorn: project().isPublic,
                          }} />
                          <div class="message">{t(`projects.management.public_access.${project().isPublic ? 'enabled' : 'disabled'}`)}</div>
                          <div class="note">{t(`projects.management.public_access.${project().isPublic ? 'enabled' : 'disabled'}.note`)}</div>
                          <Show when={project().isPublic}>
                            <a class="store_link" href={linkStoreProject(project().id)} target="_blank">{t('projects.store_page')}</a>
                            <Show when={project().userPossibleActions.indexOf('patchBranch') >= 0}>
                              <h3>{t('projects.management.public_access.branches')}</h3>
                              <For each={branches()}>{(branch) =>
                                <label class="branch"><input type="checkbox" checked={branch.isPublic} onChange={[onBranchTogglePublicAccess, branch.id]} />{branch.title}</label>
                              }</For>
                            </Show>
                          </Show>
                          <Show when={project().userPossibleActions.indexOf('patchProject') >= 0}>
                            <div class="footer">
                              <div class="buttons">
                                <Button danger onClick={onToggleProjectIsPublic}><FaIcon solid inline weak {...{
                                  lock: project().isPublic,
                                  bullhorn: !project().isPublic,
                                }} />{t(`projects.management.public_access.${project().isPublic ? 'disable' : 'enable'}`)}</Button>
                              </div>
                            </div>
                          </Show>
                        </div>
                      </div>
                      <div class="footer">
                        <ProjectActionsButtons project={project} title={t('projects.management.manage')} items={[
                          {
                            key: 'rename',
                            title: t('projects.rename'),
                            action: 'patchProject',
                          },
                          {
                            key: 'edit_description',
                            title: t('projects.edit_description'),
                            action: 'patchProject',
                          },
                          {
                            key: 'leave',
                            title: t('projects.leave'),
                            danger: true,
                            action: 'leaveProject',
                          },
                          {
                            key: 'delete',
                            title: t('projects.delete'),
                            danger: true,
                            action: 'deleteProject',
                          },
                        ]} onSelect={onProjectAction} />
                      </div>
                    </>;
                  },
                },
                {
                  component: PageNotFound,
                },
              ]} />
            </Show>
          </>;
        },
      },
    ]} />
  </>;
};

// reasonably calculate next version, i.e. 1.0.0 => 1.0.1
const calculateNextVersionTitle = (versionTitle?: string) => {
  if(!versionTitle) return null;
  const pieces = versionTitle.split('.');
  if(pieces.length <= 0) return null;
  const lastPiece = pieces[pieces.length - 1];
  if(/^[0-9]+$/.exec(lastPiece)) {
    return pieces.toSpliced(pieces.length - 1, 1, String(Number.parseInt(lastPiece) + 1)).join('.');
  }
  return `${versionTitle}.1`;
};
